import { createSelector } from 'reselect'

import { IReduxStore } from '@/App/redux/loadReducers'
import { IpodcastInfo } from '../redux/typescript'

const selector_pickPodcastsToDiscover = createSelector(
  (state: IReduxStore) => {
    if (!state || !state.yleDiscoverPodcastsStore || !state.yleDiscoverPodcastsStore.httpRequest) {
      return false
    }
    const { res } = state.yleDiscoverPodcastsStore.httpRequest
    return res && res.body
  },
  (state: IReduxStore) => {
    if (!state || !state.yleDiscoverPodcastsStore || !state.yleDiscoverPodcastsStore.httpRequest) {
      return false
    }
    const { res } = state.yleDiscoverPodcastsStore.httpRequest
    // todo: currently for quickfix cache reason, can't memoize array
    return (res && res.body && res.body.length) || -1
  },
  (availablePodcasts, length): IpodcastInfo[] | false => {
    if (!availablePodcasts) return false
    if (length < 1) return false

    return availablePodcasts.slice(0, 20)
  }
)

export { selector_pickPodcastsToDiscover }
