import { combineReducers } from 'redux'

// module reducers and typescript interfaces
import { reducer_audioPlayer } from '@/modules/audioPlayer/redux/reducer'
import { Istore_audioPlayer } from '@/modules/audioPlayer/redux/typescript'

import { reducer_yleDiscoverPodcasts } from '@/modules/yleDiscoverPodcasts/redux/reducer'
import { Istore_yleDiscoverPodcasts } from '@/modules/yleDiscoverPodcasts/redux/typescript'

// add module store interfaces
export interface IReduxStore extends Istore_audioPlayer, Istore_yleDiscoverPodcasts {}

// module reducers are added here
const reducersCollection = { ...reducer_yleDiscoverPodcasts, ...reducer_audioPlayer }

const loadReducers = combineReducers(reducersCollection)

export { loadReducers }
