// npm
import * as React from 'react'
import { connect } from 'react-redux'

// selectors
import { selector_pickPodcastsToDiscover } from '@/modules/yleDiscoverPodcasts/selectors/selector_pickPodcastsToDiscover'
import { selector_activePodcastInfo } from '@/modules/yleDiscoverPodcasts/selectors/selector_activePodcastInfo'

// typescript
import { IReduxStore } from '@/App/redux/loadReducers'
import { IactivePodcast } from '@/modules/yleDiscoverPodcasts/redux/typescript'

// styles
import './ClipPaginationIndicators.scss'
import { audioPlayerConnect } from '@/modules/audioPlayer/Components/audioPlayerConnect'

// typescript
interface IProps {
  podcastsToDiscover: any
  activePodcastInfo: IactivePodcast
  currentTimePrecentage: number
}

const mapStateToProps = (state: IReduxStore) => {
  return {
    podcastsToDiscover: selector_pickPodcastsToDiscover(state),
    activePodcastInfo: selector_activePodcastInfo(state)
  }
}

const ActiveIndicatorItem = audioPlayerConnect('discover-podcasts')(
  React.memo(
    props => {
      /// todo: fix typescript props
      const { currentTimePrecentage, selected } = props

      const style = selected ? { transform: `translateX(-${100 - currentTimePrecentage}%)` } : {}

      return (
        <div styleName="clip-splash-item--indicator-item_active" key={-1}>
          <div styleName="clip-splash-item--indicator-item_selected-animated" style={style} />
        </div>
      )
    },
    (prev, curr) => {
      if (prev.selected !== curr.selected) return false
      if (curr.selected && prev.currentTimePrecentage !== curr.currentTimePrecentage) return false
      return true
    }
  )
)

const ClipPaginationIndicatorsComponent = React.memo((props: IProps) => {
  const { activePodcastInfo, podcastsToDiscover } = props

  if (!podcastsToDiscover.length)
    return (
      <div styleName="clip-splash-item--indicator-wrapper">
        <ActiveIndicatorItem key={-1} />
      </div>
    )

  return (
    <div styleName="clip-splash-item--indicator-wrapper">
      {podcastsToDiscover.reduce(
        (acc, curr, index) => {
          const lastItemIndex = podcastsToDiscover.length - 1
          // going backwards in the list to display all previous indicators as "active"
          const currentItem = podcastsToDiscover[lastItemIndex - index]
          const isSelected = currentItem.yleId === activePodcastInfo.yleId
          const isActive = acc.foundActive || isSelected
          const key = currentItem.yleId

          const newAcc = { foundActive: isActive, componentCollection: acc.componentCollection }
          // going backwards in the list to display all previous indicators as "active"
          if (isActive) {
            newAcc.componentCollection.unshift(<ActiveIndicatorItem selected={isSelected} key={key} />)
          } else {
            newAcc.componentCollection.unshift(<div styleName="clip-splash-item--indicator-item" key={key} />)
          }

          if (index === lastItemIndex) return acc.componentCollection

          return newAcc
        },
        { foundActive: false, componentCollection: [] }
      )}
    </div>
  )
})

const ClipPaginationIndicators = connect(mapStateToProps)(ClipPaginationIndicatorsComponent)

export { ClipPaginationIndicators }
