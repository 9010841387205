import { STORE_NAME } from '.'

// analytics
import { YTMLog } from '@/modules/yleTagManager/yleTagManager'

// selectors
import { selector_activePodcastInfo } from '../selectors/selector_activePodcastInfo'
import { selector_pickPodcastsToDiscover } from '../selectors/selector_pickPodcastsToDiscover'

// typescript
import { IactivePodcast, IpodcastInfo } from './typescript'
import { selector_discoverPodcastsLoading } from '../selectors/selector_discoverPodcastsLoading'

export const GO_NEXT_ACTIVE_PODCAST = `${STORE_NAME}::goNextActivePodcast`
export const GO_PREVIOUS_ACTIVE_PODCAST = `${STORE_NAME}::goNextPreviousActivePodcast`
export const END_REACHED = `${STORE_NAME}::endReached`
export const DEBUG_ADD_PODCAST = `${STORE_NAME}::debugAddPodcast`
//
export const FETCH_DISCOVER_PODCASTS = `${STORE_NAME}::fetchDiscoverPodcasts`
export const FETCH_DISCOVER_PODCASTS_SUCCESS = `${STORE_NAME}::fetchDiscoverPodcasts_success`
export const FETCH_DISCOVER_PODCASTS_ERROR = `${STORE_NAME}::fetchDiscoverPodcasts_error`
//
export const SET_ACTIVE_DISCOVER_PODCASTS = `${STORE_NAME}::setActiveDiscoverPodcast`

// audio
import audio1 from '@/assets/audioClips/1-4448405_clip.mp3'
import audio2 from '@/assets/audioClips/1-50149108_clip.mp3'
import audio3 from '@/assets/audioClips/1-50187558_clip.mp3'
import audio4 from '@/assets/audioClips/1-50250244_clip.mp3'
import audio5 from '@/assets/audioClips/1-50250429_clip.mp3'
import audio6 from '@/assets/audioClips/1-50288889_clip.mp3'
import audio7 from '@/assets/audioClips/1-50313721_clip.mp3'
import audio8 from '@/assets/audioClips/1-4140197_clip.mp3'
import audio9 from '@/assets/audioClips/1-4303190_clip.mp3'
import audio10 from '@/assets/audioClips/1-50363205_clip.mp3'
import audio11 from '@/assets/audioClips/1-50399750_clip.mp3'
import audio12 from '@/assets/audioClips/1-50471616_clip.mp3'
import audio13 from '@/assets/audioClips/1-50494839_clip.mp3'

// images
import img1 from '@/assets/images/13-1-4448405.jpg'
import img2 from '@/assets/images/50149108.jpg'
import img3 from '@/assets/images/13-12-1021-4-131917-main.jpg'
import img4 from '@/assets/images/13-12-1022-4-1004106-main.jpg'
import img5 from '@/assets/images/13-12-1021-4-1001084-main.jpg'
import img6 from '@/assets/images/13-12-1021-4-1002813-main.jpg'
import img7 from '@/assets/images/13-12-1021-4-1003578-main.jpg'
import img8 from '@/assets/images/13-1-4140186.jpg'
import img9 from '@/assets/images/13-1-4303190.jpg'
import img10 from '@/assets/images/13-1-2320317-1583144179933.jpg'
import img11 from '@/assets/images/13-12-1025-4-1004925-main.jpg'
import img12 from '@/assets/images/13-12-1021-4-1013583-main.jpg'
import img13 from '@/assets/images/13-1-1485162-1547463769470.jpg'

export const action_goNextActivePodcast = (userInitiated?: boolean) => {
  return (dispatch, getState) => {
    const state = getState()
    const availablePodcasts = selector_pickPodcastsToDiscover(state)
    const { index } = selector_activePodcastInfo(state)

    let nextId
    let nextIndex

    if (index > -1 && availablePodcasts && availablePodcasts.length - 1 > index) {
      // next
      nextId = availablePodcasts[index + 1].yleId
      nextIndex = index + 1

      const payload: IactivePodcast = {
        yleId: nextId,
        index: nextIndex
      }

      // todo: cleanup, repetitive with goPreviousActivePodcast
      if (typeof Storage !== 'undefined') {
        const itemKey = `default::${SET_ACTIVE_DISCOVER_PODCASTS}`
        localStorage.setItem(itemKey, JSON.stringify(payload))
      }

      // analytics
      if (userInitiated) YTMLog({ userManualNextClip: { yle_id: nextId } })

      // back to beginning
      dispatch({
        type: GO_NEXT_ACTIVE_PODCAST,
        payload
      })
    }
  }
}

export const action_goPreviousActivePodcast = (userInitiated?: boolean) => {
  return (dispatch, getState) => {
    const state = getState()
    const availablePodcasts = selector_pickPodcastsToDiscover(state)
    const { index } = selector_activePodcastInfo(state)

    const nextIndex = index - 1

    if (index > -1 && nextIndex > -1) {
      const nextId = availablePodcasts[index - 1].yleId

      const payload: IactivePodcast = {
        yleId: nextId,
        index: nextIndex
      }

      // todo: cleanup, repetitive with goNextActivePodcast
      if (typeof Storage !== 'undefined') {
        const itemKey = `default::${SET_ACTIVE_DISCOVER_PODCASTS}`
        localStorage.setItem(itemKey, JSON.stringify(payload))
      }

      // analytics
      if (userInitiated) YTMLog({ userManualPreviousClip: { yle_id: nextId } })

      // back to beginning
      dispatch({
        type: GO_PREVIOUS_ACTIVE_PODCAST,
        payload
      })
    }
  }
}

export const action_debug_addPodcast = () => {
  return {
    type: DEBUG_ADD_PODCAST
  }
}

export const action_endReached = () => {
  return (dispatch, getState) => {
    const state = getState()
    const availablePodcasts = selector_pickPodcastsToDiscover(state)

    const nextIndex = 0

    const nextId = availablePodcasts[0].yleId

    const payload: IactivePodcast = {
      yleId: nextId,
      index: nextIndex
    }

    // todo: cleanup, repetitive with goNextActivePodcast
    if (typeof Storage !== 'undefined') {
      const itemKey = `default::${SET_ACTIVE_DISCOVER_PODCASTS}`
      localStorage.setItem(itemKey, JSON.stringify(payload))
    }

    // back to beginning
    dispatch({
      type: GO_PREVIOUS_ACTIVE_PODCAST,
      payload
    })
  }
}

export const action_fetchDiscoverPodcasts = () => {
  return (dispatch, getState) => {
    const isAlreadyLoading = selector_discoverPodcastsLoading(getState())

    if (isAlreadyLoading) return

    dispatch({
      type: FETCH_DISCOVER_PODCASTS
    })

    // fake api call simulator 2000
    setTimeout(() => {
      const exampleBody: IpodcastInfo[] = [
        {
          yleId: `1-4448405`,
          audioSrc: audio1,
          thumbnailSrc: img1,
          episodeTitle: 'Perttu Häkkinen',
          episodeName: 'Musiikki ja magia',
          episodeDescription:
            '<p>Tällä kerralla astumme Perttu Häkkisen kanssa populaarimusiikin salattuun maailmaan. Ovatko bändipaitojen pentagrammit ja pedonluvut vain ulkokultaista flirttiä okkultismiin vai löytyykö taustalta syvempää salatiedettä? Aiheesta keskustelemassa okkultis-filosofisen Azaelin tähti -seuran Johannes Nefastos, tutkija-rockmuusikko Antti Litmanen, sekä Hexvessel-yhtyeen laulaja-perkussionisti Marja Konttinen. Mukana johdatuksessa esiintyy myös Häkkisen radio-ohjelmasta tuttu toimittajakollega Panu Hietaneva, joka keskustelee Kollaa Kestää -yhtyeen Jyrki Siukosen kanssa Jäähyväiset aseille -hittikappaleen synnystä. Ohjelma on tallennettu elävän yleisön edessä Helsingin Sideways-festivaleilla 9.6.2018.</p>',
          podcastSeriesDescription:
            '<p>Suomessa asuu viisi miljoonaa ihmistä ja heillä on viisi miljoonaa tapaa elää. Tässä ohjelmassa tutustutaan muutamiin niistä. Toimittajina Perttu Häkkinen ja Panu Hietaneva.</p>',
          fullPodcastDuration: '0:57',
          publishedDate: '2018-06-12T15:00:00.270Z'
        },
        {
          yleId: `1-50149108`,
          audioSrc: audio2,
          thumbnailSrc: img2,
          episodeTitle: 'Juuso Pekkinen',
          episodeName: 'Kiina pisteyttää kansalaisiaan, valvoo huipputeknologialla ja pyrkii tekoälyn suurvallaksi',
          episodeDescription:
            '<p>Suomessakin on seurattu, kuinka Kiina pyrki rakentamaan kansalaisten "sosiaalista pisteytysjärjestelmää". Onko kyseessä dystooppinen teknologiavisio vai "luottamuksen kulttuuria" rakentava ja ylläpitävä systeemi? Kiinalaiset valtiota lähellä olevat jättiyritykset ovat ryhtyneet myymään mm. kasvojentunnistusta hyödyntävää valvontateknologiaa maailmalle. Mistä tässä on kyse? Ja tuleeko Kiinasta tekoälyn suurvalta 2030 mennessä? Tässä jaksossa puhutaan Kiinasta ja teknologiasta! Juuso Pekkisen haastateltavana on dosentti Outi Luova. Luova toimii yliopistonlehtorina Turun yliopiston Itä-Aasian tutkimus- ja koulutuskeskuksessa.</p>',
          podcastSeriesDescription: `<p>Maanantaisin klo 14, Yle Puhe ja Yle Areena.</p>
          <p>Kohtaamisia syvässä päässä. Juuso Pekkinen etsii suurempaa ymmärrystä ympäröivästä todellisuudesta ja tarjoilee hydraatiota intohimoiseen tiedonjanoon.</p>`,
          fullPodcastDuration: '0:58',
          publishedDate: '2019-05-27T15:00:00.270Z'
        },
        {
          yleId: `1-50187558`,
          audioSrc: audio3,
          thumbnailSrc: img3,
          episodeTitle: '12 diktaattoria',
          episodeName: 'Kulttijohtajat: Charles Manson perusti Perhe-murhakultin',
          episodeDescription:
            '<p>Beatlesia fanittanut Charles Manson perusti Perhe-nimisen murhakultin 1960-luvulla. Charles Manson haaveili rokkitähteydestä ja hänestä piti tulla The Beatlesia suurempi. Rokkitähteä hänestä ei kuitenkaan tullut, mutta kuuluisa tuli ja se oli Mansonin haaveista suurin. Hän piti sarjamurhillaan Los Angelesia kauhun vallassa. Manson oli ensimmäinen pop-aikakauden joukkomurhaaja, joka onnistui löytämään Beatlesin White-albumilta piiloviestejä, jotka yllyttivät rotusotaan mustien ja valkoisten välillä. Kulttijohtaja Charles Mansonista kertoo Israelin Ystävät ry:n toiminnanjohtaja ja Shalom-lehden päätoimittaja Ilkka Vakkuri. Toimittajana Raimo Tyykiluoto.</p>',
          podcastSeriesDescription: `<p>Lähihistoriamme kuuluisia ja vähemmän tunnettuja hirmuhallitsijoita</p>
          <p>Kesällä 12 diktaattoria sarjassa kuullaan villi tusina. Kahdentoista kulttijohtajan tarinat pistävät veren kiertämään kylmimmässäkin kesäsäässä. Esittelyssä ovat mm. Sun Myung Moon, Keith Raniere ja Charles Manson.</p>
          <p>Ohjelmasarjassa pohditaan, kuinka niin monesta ”munkkirinkelin myyjästä” ja ”mainiosta hepusta”, joista osa nousi valtaan jopa demokraattisesti vaaleilla ja alkuun teki mittavia uudistuksia maansa talouden kohentamiseksi, kuoriutui lopulta hirmuhallitsijoita, jotka naulasivat vastustajansa kiveksistä ladon seinään ja tarjoilivat toisinajattelijat paahtopaistina vierailleen. Toimittajana Raimo Tyykiluoto.</p>
          `,
          fullPodcastDuration: '0:30',
          publishedDate: '2019-06-17T20:16:35.270Z'
        },
        {
          yleId: `1-50250244`,
          audioSrc: audio4,
          thumbnailSrc: img4,
          episodeTitle: 'Tiedeykkönen',
          episodeName: 'Mieti mihin aivosi viet? Mikä vahvistaa stressistä selviämistä?',
          episodeDescription: `<p>Stressissä ihmiset aivot sopeutuvat negatiiviseen ympäristöön. Näin käy nimenomaan stressin pitkittyessä eikä ihminen pysty palautumaan. Stressaantuneen ihmisen aivoissa mantelitumake suurentuu, mutta aivojen kuorikerroksessa etuotsalohko puolestaan kutistuu.</p>
          <p>Mantelitumakkeen toiminta liittyy tunnemuistiin ja pelkoreaktioihin. Aivojen kuorikerros vastaa tietoisuustaidoista ja toiminnanohjauksesta, siksi stressiin liittyviä tunteita ja ajatuksia on niin vaikea säädellä, sanoo psykologian tohtori Satu Pakarinen Työterveyslaitokselta.</p>
          <p>Pakarinen on tutkinut kollegoidensa kanssa ydinvoimalan valvomohenkilöstön toimintaa stressitilanteessa. Mitä tutkimuksessa saatiin selville ja kuinka se on sovellettavissa muihin ammatteihin? Toimittaja Teija Peltoniemi kysyy erikoistutkija Satu Pakariselta, onko parempi stressin taltuttamisen kannalta vältellä itse ongelmaa vai tarttua siihen päätä pahkaa?</p>`,
          podcastSeriesDescription: `<p>Tiistaisin ja perjantaisin klo 12.10, Yle Radio 1</p>
          <p>Kuuntele, ymmärrä - oivalla. Mitä tieteessä tapahtuu nyt ja mihin olemme menossa? Kertomuksia maailmankaikkeuden ääristä ihmiskehon syövereihin.</p>
          <p>Toimittajina Pirjo Koskinen, Leena Mattila, Teija Peltoniemi ja Riikka Warras.</p> 
          <p>Ohjelmaa avustavat tiedetoimittajat Mari Heikkilä, Sisko Loikkanen, Jari Mäkinen ja Ville Talola.</p> 
          <p>Tuottajana Maija Kaipainen.</p>`,
          fullPodcastDuration: '0:47',
          publishedDate: '2020-10-11T20:16:35.270Z'
        },
        {
          yleId: `1-50250429`,
          audioSrc: audio5,
          thumbnailSrc: img5,
          episodeTitle: 'Kaverin puolesta kyselen',
          episodeName: 'Kolmen kimppa eksän kanssa ja muita treffitarinoita',
          episodeDescription:
            '<p>Jakso 86. Mitä jos deiteillä pääsee kunnon rupsu? Kaverin puolesta kyselen. Kaverin asialla Anna Karhunen ja Tiia Rantanen.</p>',
          podcastSeriesDescription: `<p>Noloja tarinoita ja varmat naurut</p>
          <p>Kenellä on pahimmat mokat? No kaverilla. Anna Karhunen ja Tiia Rantanen kertovat kiusallisimmat jutut ja noloimmat sattumukset, joissa pääosassa on aina kaveri – tietenkin. Podcast tarjoaa varmat naurut ja helpotusta omaan (tai kaverin) häpeään. Uusi jakso joka perjantai!</p>`,
          fullPodcastDuration: '0:17',
          publishedDate: '2019-09-06T20:16:35.270Z'
        },
        {
          yleId: `1-50288889`,
          audioSrc: audio6,
          thumbnailSrc: img6,
          episodeTitle: 'Radio Sodoma',
          episodeName: 'Jakso 16: TOINEN KAUSI ALKAA! Sodoman Uutiset ja poliitikkojen Häpeäpalloturnaus',
          episodeDescription: `<p>Radio Sodoma palaa pahan taajuudelle! Sodoman Uutiset haastattelee helvetin uutta johtoa, ja Seurapiiriuutisissa Randi Garutan paljastaa potkut saaneen Saatanan salarakkaan. Sodoman Urheiluillassa poliitikkojen Häpeäpalloturnaus nostaa punan poskille. Kalevi Tuoninen palaa Radio Sodoman kuulijoiden oppaaksi.</p>
          <p>Sisältövaroitukset: kuolema, väkivallan kuvaus, misogynia, transfobia, burnout. Radio Sodoma sisältää voimakasta kielenkäyttöä.</p>
          <p>Radio Sodoma on Antti Holman kuvitteellinen radiokanava helvetissä.</br>
          Kaikki hahmot on kirjoittanut ja näyttelee Antti Holma.</p>`,
          podcastSeriesDescription: `<p>Antti Holman helvetillisen hyvä podcast</p>
          <p>Radio Sodoma palaa pimeän taajuudelle! Toisella kaudella (jaksosta 16 alkaen) helvetissä puhaltavat uudet tuulet, Saatana on irtisanottu helvetin johdosta, ja myös Radio Sodoma käy läpi päättymätöntä kanavauudistusta. Miten käy, kun markkinavoimat kohtaavat pahuuden voimat?</p>
          <p>1. kausi, jaksot 1-15<br/>2. kausi, jaksot 16-34</p>
          <p>Radio Sodoma on helvetin ainut ja suosituin radiokanava. Sen kuolematon juontaja Kalevi Tuoninen vie kuulijan matkalle Sodoman pimeään maailmaan. Radio Sodoman lähetykset koostuvat lähes sataprosenttisesti mustasta huumorista, jota on laimennettu yhteiskunnallisella keskustelulla ja peräaukkovitseillä, mutta ei kuitenkaan tarpeeksi. </p>
          <p>Radio Sodoma on kuvaus helvetistä, joka joskus kuulostaa Suomelta. Se on kuin kolmiolääke – sen nauttimisen aikana kuulijoita kehotetaan varovaisuuteen liukkailla pinnoilla ja vakavissa olosuhteissa. Radio Sodoma sisältää voimakasta kielenkäyttöä ja herkimpiä kuulijoita loukkaavaa sisältöä.</p>
          <p>Radio Sodoman kaikki ohjelmat on kirjoittanut ja näyttelee Antti Holma. Podcastin on äänisuunnitellut Suvi Tuuli Kataja. Podcastin toinen kausi julkaistaan yksinoikeudella Yle Areenassa 24.9., ja muilla alustoilla 11.10.2019. Ensimmäiset 15 jaksoa julkaistiin kesällä 2017.</p>
          <p>Tuotanto: Yle Draama<br/>Käsikirjoitus ja hahmot: Antti Holma<br/>Tuottaja: Elina Ylä-Mononen, Pekka Ruohoranta<br/>Äänisuunnittelu: Suvi Tuuli Kataja<br/>Kuvitus: Tuuli Laukkanen<br/>Valokuvaus: Jukka Lintinen<br/>Videot: kuvaus Jussi Nahkuri, leikkaus Sanna Rusthollkarhu, animointi Tommi Anttila<br/>Verkkotuotanto: Inka Hannonen, Ulla Broholm, Salla-Rosa Leinonen<br/>
          Musiikki: Epidemic Sound; jakso 4 ja jakso 14 alkuperäiskappaleet Antti Holma ja Visa Mertanen.</p>
          <p>Kiitos kaikille, jotka ovat auttaneet Radio Sodoman tekemisessä: Petri Alanko, Liila Jokelin, Aapo Koistinen, Laura Koso, Joonatan Kotila, Pietari Kylmälä, Antti Lehtinen, Mikko Lohenoja, Mari Lukkari, Tiina Luoma, Lauri Medina, Pasi Nevalaita, Tuukka Pasanen, Kasper Salonen, Sydän, sydän; J. H. Tiihonen, Jouni-Pekka Uitto, Soila Valkama, Tuomas Vauhkonen ja Marja Vehkanen.</p>`,
          fullPodcastDuration: '0:15',
          publishedDate: '2019-09-24T20:16:35.270Z'
        },
        {
          yleId: `1-50313721`,
          audioSrc: audio7,
          thumbnailSrc: img7,
          episodeTitle: 'Ruben Stiller',
          episodeName: 'Voiko elämänhallinta mennä överiksi?',
          episodeDescription:
            '<p>Maailma näyttää kaoottiselta, mutta elämänhallintaoppaat ja terveysvinkit luovat uskoa, että elämä-projekti voidaan saada haltuun. Itsensä kehittämisestä on tullut teollisuudenhaara, jonka ydintä ovat self-help –kirjallisuus ja life coach –bisnes. Voiko elämänhallinta mennä liian pitkälle? Miten elämään pitäisi valmentaa? Ruben Stillerin vieraina ovat kirjailija Anna Perho, liikemies Jari Sarasvuo ja elämäntapavalmentaja Maaretta Tukiainen. Onko Rubenilla mitään toivoa tulla paremmaksi ihmiseksi?</p>',
          podcastSeriesDescription: `<p>Onko arvoillammekin parasta ennen -päivämäärä?</p>
          <p>Ruben Stillerin keskusteluohjelmassa puhutaan arvokkaasti Suomen arvoista, arvojohtajista ja arvottomista. Ohjelman moniarvoisessa ilmapiirissä ruoditaan ajan ilmiöitä, puheenaiheita ja kansakunnan kohtalonkysymyksiä. </p>  
          <p>Vieraina on tunnettuja ja tuntemattomia näkijöitä, tekijöitä ja kokijoita.</p>
          <p>Ohjelmassa kysytään, kuka oikein käsikirjoittaa Suomen suurta draamaa ja kuka kuittaa esityspalkkiot.</p>`,
          fullPodcastDuration: '0:58',
          publishedDate: '2020-10-25T20:16:35.270Z'
        },
        {
          yleId: `1-4140197`,
          audioSrc: audio8,
          thumbnailSrc: img8,
          episodeTitle: 'Depressiopäiväkirjat',
          episodeName: 'Jakso 14: MITÄ?',
          episodeDescription: `<p>Jos ei ole koskaan kohdannut mieltään sairastavaa ihmistä, on hankala olla empaattinen. On hankalaa ymmärtää. Mutta yrittäkää nyt edes.</p>
          <p>Käsikirjoitus: Anni Saastamoinen<br/>
          Äänisuunnittelu, musiikki, äänitys: Jarkko Rissanen, Anni Saastamoinen, Matt Stocks Mukana rooleissa: Jonna Mäkelä, Jarkko Rissanen, Sanni Pärssinen<br/>
          Kiitos: Laajasalon opisto</p>`,
          podcastSeriesDescription: `<p>Masennus – miltä se tuntuu ja mitä se todella on?</p>
          <p>Depressiopäiväkirjat-podcast nostaa peiton masennuksen yltä ja kertoo, miltä se tuntuu ja mitä se todella on.</p> 
          <p>Käsikirjoitus: Anni Saastamoinen <br/>
          Äänisuunnittelu, musiikki, äänitys: Jarkko Rissanen, Anni Saastamoinen, Matt Stocks Mukana rooleissa: Jonna Mäkelä, Jarkko Rissanen, Sanni Pärssinen<br/>
          Kiitos: Laajasalon opisto</p>`,
          fullPodcastDuration: '0:04',
          publishedDate: '2017-05-15T20:16:35.270Z'
        },
        {
          yleId: `1-4303190`,
          audioSrc: audio9,
          thumbnailSrc: img9,
          episodeTitle: 'Noin viikon radio',
          episodeName: 'Vieraana Atte Järvinen',
          episodeDescription: `<p>Ylimääräisessä Noin viikon radion jaksossa vieraana on käsikirjoittaja Atte Järvinen. Hän on kirjoittanut lukuisia suomalaisia tv-ohjelmia, mutta parhaiten hänet tunnetaan suositun animaatiosarjan Pasilan luojana.</p>
          <p>Noin viikon radion toistaiseksi viimeisessä jaksossa kuullaan Atte Järvisen ajatuksia mm. käsikirjoittamisesta, satiirista sekä politiikasta. Lisäksi keskustelussa päädytään puhumaan siitä, miksi jotkut tv-ohjelmat on hyvä lopettaa ajoissa.</p>
          <p>Noin viikon radio on Jukka Lindströmin syy päästä tapaamaan mielenkiintoisia ihmisiä. Tämä on sarjan toistaiseksi viimeinen jakso.</p>`,
          podcastSeriesDescription: `Noin viikon radio on Jukka Lindströmin syy päästä tapaamaan mielenkiintoisia ihmisiä.`,
          fullPodcastDuration: '1:20',
          publishedDate: '2017-12-08T20:16:35.270Z'
        },
        {
          yleId: `1-50363205`,
          audioSrc: audio10,
          thumbnailSrc: img10,
          episodeTitle: 'Lindgren & Sihvonen',
          episodeName: 'Urheilun unohtumaton ääni – vieraana selostajalegenda Juha Jokinen',
          episodeDescription: `<p>Vuosikymmenen viimeisen lähetyksen juhlavieraaksi saapuu Urheiluruudun ikoninen tv-kasvo ja yksi suomalaisen urheiluhistorian tutuimmista äänistä: Juha Jokinen.</p>
          <p>Vuonna 1937 syntynyt Jokinen on yksi suomalaisen urheiluselostuksen ja erityisesti tv-selostuksen pioneereja, jonka ääni välitti suomalaiskoteihin lukuisia 1900-luvun merkittävimpiä urheilutapahtumia.</p>
          <p>Vuoden 2014 Urheilugaalassa hänet palkittiin työstään Urheilun sinivalkoiset äänet -tunnustuksella yhdessä viiden muun selostajan kanssa. Jokinen muistetaan etenkin työstään suunnistuksen Jukolan viestin, F1:n, jääkiekon sekä tenniksen parissa, mutta myös monista kesä- ja talviolympialaisten selostuksistaan.</p>
          <p>Juha Jokisen kanssa palataan lähetyksessä suomalaisen tv-selostuskulttuurin alkuvuosiin, hänen työskentelytapoihinsa sekä ajatuksiin kollegoista ja aikalaisista. Millaista oli yhteistyö esimerkiksi Pentti Salmen, Anssi Kukkosen tai Seppo Kannaksen kanssa?</p>
          <p>Entä millaisia tuntoja Jokisessa herättävät tallenteet omista selostuksista mm. Lake Placidin, Los Angelesin ja Soulin olympialaisista? Miten Yleisradion selostajalegenda kommentoi urheilun nykyistä selostuskulttuuria 2010-luvun lähestyessä loppuaan?</p>`,
          podcastSeriesDescription:
            'Tommy Lindgren ja Petteri Sihvonen tähdittävät humoristista, mutta tiukkaa asiaa sisältävää keskusteluohjelmaa urheilusta Yle Puheen perjantaissa.',
          fullPodcastDuration: '0:58',
          publishedDate: '2019-12-20T12:00:00.270Z'
        },
        {
          yleId: `1-50399750`,
          audioSrc: audio11,
          thumbnailSrc: img11,
          episodeTitle: 'Viki ja Köpi Podcast',
          episodeName: 'EXTRA: Loppuvuoden parhaat läpät',
          episodeDescription: `<p>Vikin ja Köpi loppyvuoden parhaimmat läpät mukavassa kahden tunnin paketissa!</p>
          <p>YleX Aamun Viki ja Köpi suorana Yle Areenassa arkisin klo 6.30 alkaen!</p>`,
          podcastSeriesDescription: `<p>YleX Aamun viikon parhaimmat läpät</p>
          <p>Tämä on podcast sinulle, joka haluat nauttia YleX Aamun koko viikon sisällön nopeasti ja kivuttomasti. Lupaamme, että tästä podcastista et opi mitään. Sen sijaan tarjoamme noin puoli tuntia verbaalista huumoria aina kahden markan imitaatioista paskimpiin isävitseihin. Tässä on tulos, kun kaksi aikuista ihmistä laitetaan liian usein ja liian pitkäksi aikaa hikiseen studiokoppiin.</p>`,
          fullPodcastDuration: '2:01',
          publishedDate: '2020-01-03T12:00:00.270Z'
        },
        {
          yleId: `1-50471616`,
          audioSrc: audio12,
          thumbnailSrc: img12,
          episodeTitle: 'Yhden yön juttuja',
          episodeName: 'Se oli niin noloa!',
          episodeDescription: `<p>Vahinko-superliket, viestit ihan vääriin chatti-ryhmiin ja pikkuruiset bikinit. Thelma, Amelie ja Hennariikka vaipuvat maan rakoon.</p>`,
          podcastSeriesDescription: `<p>Mitä mielessä pyörii kun oikeesti pitäis jo nukkua</p>
          <p>Uusi päivä -sarjan kuvauksissa tutustuneet näyttelijät Hennariikka Laaksola, Amelie Blauberg ja Thelma Siberg käyvät huippusuositussa podcastissaan läpi tunteita ja suhteita, raha-asioita ja tulevaisuutta, rakkautta ja niitä ihan omia asioita. </p> 
          <p>Erilaisista elämäntilanteista tulevat naiset ihmettelevät toistensa valintoja, vaikka useimmiten naurun seasta kuuluva kommentti onkin: "Sie oot just tuommonen!"</p>
          <p>Tuotanto: Mediagents Oy</p>
          <p>Yhden yön juttuja - mitä mielessä pyörii kun oikeesti pitäis jo nukkua.
          IG @yhdenyonjuttuja</p>`,
          fullPodcastDuration: '0:25',
          publishedDate: '2020-03-12T12:00:00.270Z'
        },
        {
          yleId: `1-50494839`,
          audioSrc: audio13,
          thumbnailSrc: img13,
          episodeTitle: 'Politiikkaradio',
          episodeName: 'Puheet päreiksi: Koronatukipaketteja ja kehittämisrahaa',
          episodeDescription: `<p>Yritysten koronatukimiljoonista äityi valtaisa kohu. Erityisesti Business Finlandin jakamat tuet puhuttavat: koronarahoja on mennyt konsulteille, hyvää tulosta tekeville yrityksille, tai vaikkapa huutokauppakeisarille. Miksi koronatukiraha olisi parempaa rahaa kuin kehittämisraha? Saiko somelynkkaus huutokauppakeisarinkin luopumaan bisneksensä kehittämisprojektista?</p>
          <p>Onko koronatalouspuhe muutakin kuin tukipuhetta? Eroaako kotikutoinen koronakriisipuhe EU-tason tukipakettipuheesta?</p>
          <p>Minkä koronastrategian Suomi valitsee: jarruttaminen, tukahdutus vai hallittu avaaminen?</p>
          <p>Mikä on päivän politiikan sana?</p>
          <p>Suomen kielen dosentti Vesa Heikkinen ja toimittaja Tapio Pajunen puivat politiikan kielen ajankohtaisuuksia.</p>`,
          podcastSeriesDescription: `<p>Arkisin klo 12.30, Yle Puhe ja Yle Areena</p>
          <p>Politiikkaa on kaikkialla, ja politiikka on meidän kaikkien asia. Politiikkaradio on myös ohjelma yhteiskunnasta, sen kiinnostavista ilmiöistä ja ihmisistä meillä ja maailmalla. Toimittajina Linda Pelkonen ja Tapio Pajunen. </p> `,
          fullPodcastDuration: '0:36',
          publishedDate: '2020-04-24T12:00:00.270Z'
        }
      ]

      const res = {
        statusCode: 200,
        body: exampleBody
      }

      dispatch({
        type: FETCH_DISCOVER_PODCASTS_SUCCESS,
        payload: { res }
      })

      // todo: cleanup. dont need to set id here, just the index (should be selector)
      let defaultSetActiveDiscoverPodcasts = { yleId: res.body[0].yleId, index: 0 }
      if (typeof Storage !== 'undefined') {
        const itemKey = `default::${SET_ACTIVE_DISCOVER_PODCASTS}`

        const existingValue = localStorage.getItem(itemKey)

        if (existingValue) {
          // todo: validate
          defaultSetActiveDiscoverPodcasts = JSON.parse(existingValue)
        } else {
          const value = { yleId: res.body[0].yleId, index: 0 }
          localStorage.setItem(itemKey, JSON.stringify(value))
        }
      }

      dispatch({
        type: SET_ACTIVE_DISCOVER_PODCASTS,
        payload: defaultSetActiveDiscoverPodcasts
      })
    }, 1000)

    // dispatch({
    //   type: FETCH_DISCOVER_PODCASTS
    // })
  }
}
