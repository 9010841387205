import * as React from 'react'

const IconYle = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 47.68">
      <defs>
        <mask id="mask" width="48.16" height="47.84" x="0" y="-0.16" maskUnits="userSpaceOnUse">
          <g transform="translate(0 -.16)">
            <g>
              <path fill="#fff" fillRule="evenodd" d="M48.16 0L0 0 0 47.84 48.16 47.84 48.16 0.16 48.16 0z" />
            </g>
          </g>
        </mask>
      </defs>
      <g>
        <path
          fill="#fff"
          d="M35.16 19.1c-2.09 0-3 1-3.21 3.54h6.1c0-2.41-1-3.54-2.89-3.54"
          transform="translate(0 -.16)"
        />
        <g>
          <g mask="url(#mask)">
            <path
              fill="#fff"
              d="M38.53 24.88h-6.42c0 2.73.48 3.37 3.85 3.37l2.57-.16h.64c.48 0 .8.16.8.81v.48a.92.92 0 01-.64 1 11.17 11.17 0 01-3.85.48c-4.66 0-6.42-1.76-6.42-6.74 0-4.82 1.92-7.22 6.1-7.22 4.81 0 5.46 3.69 5.46 6.42 0 .8-.49 1.6-2.09 1.6zm-12.68 4.5c0 .64-.16 1.28-1.13 1.28h-.64c-.8 0-1-.48-1-1.28V13.16h-.65c-1.28 0-1.6-.64-1.6-1v-1c0-.8 1.12-.8 1.44-.8h1.93c1.29 0 1.61 1 1.61 2.08zm-6.43-9.63l-3.53 14a3.87 3.87 0 01-4 2.89c-2.09 0-2.89 0-3.37-.32-.32 0-.64-.32-.64-1v-.48c0-.65.32-1 .8-1h.64l2.08.16c1.12 0 1.6-.16 2.25-1.6l.35-1.9h-1a2.42 2.42 0 01-2.73-2.25l-2.4-8.18-.32-1.77c0-.64.48-1 1.12-1h.8c.64 0 .8.48 1 1.12L12.84 27c.32 1.12.64 1.28 1.29 1.28h.32l2.25-9.63c.32-.8.48-1.12 1.28-1.12h.64a.86.86 0 011 1l-.17 1.61zM45.91.32H2.25A1.91 1.91 0 000 2.41v43.34a2.16 2.16 0 002.25 2.09h43.66A2.16 2.16 0 0048 45.59V2.25A2.12 2.12 0 0045.91.16z"
              transform="translate(0 -.16)"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export { IconYle }
