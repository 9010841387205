// npm
import * as React from 'react'
import { connect } from 'react-redux'
import { Transition, TransitionGroup } from 'react-transition-group'
import { TweenMax } from 'gsap'

// actions
import { action_playerPlay } from '@/modules/audioPlayer/redux/actions'

// icons
import { IconPlay } from '@/assets/ReactIcons/IconPlay'

// selectors
import { selector_discoverPodcastsLoaded } from '@/modules/yleDiscoverPodcasts/selectors/selector_discoverPodcastsLoaded'
import { selector_audioPlayerActiveIndex } from '@/modules/audioPlayer/selectors/selector_audioPlayerActiveIndex'

// services
import { AudioPlayerService } from '@/modules/audioPlayer/service/AudioPlayerService'

// typescript
import { IReduxStore } from '@/App/redux/loadReducers'

// styles
import './StartDiscoveringPodcastsConsentOverlay.scss'

const DoAnimation = React.memo(
  (props: any) => {
    const { buttonRef, wrapperRef } = props
    React.useEffect(
      () => {
        if (props.inProp) {
          // TweenMax.to(props.buttonRef.current, 0.6, { y: '0%', ease: 'power3.out' })
        } else {
          TweenMax.to(wrapperRef.current, 0.8, { background: 'rgba(0, 0, 0, 0.0)' })
          // TweenMax.to(iconRef.current, 0.3, { ease: 'power1.in' })
          TweenMax.to(buttonRef.current, 0.35, { opacity: 0, ease: 'power1.in' })
          TweenMax.to(buttonRef.current, 0.35, { scale: 1.15, ease: 'back.in(5)' })
        }
      },
      [props.inProp]
    )
    return props.children
  },
  (a, b) => a.inProp === b.inProp
)

const Fade = (props: any) => {
  const { in: inProp, timeout, children, buttonRef, wrapperRef, iconRef } = props

  return (
    <Transition in={inProp} timeout={timeout} unmountOnExit>
      <DoAnimation inProp={inProp} buttonRef={buttonRef} wrapperRef={wrapperRef} iconRef={iconRef}>
        {children}
      </DoAnimation>
    </Transition>
  )
}

const mapStateToProps = (state: IReduxStore) => {
  const discoverPodcastsLoaded = selector_discoverPodcastsLoaded(state)
  const playerActiveIndex = selector_audioPlayerActiveIndex(state, 'discover-podcasts')

  return { playerActiveIndex, discoverPodcastsLoaded }
}
const Content = connect(mapStateToProps)((props: any) => {
  const {
    onStartDiscoveringPodcast,
    playerActiveIndex,
    discoverPodcastsLoaded,
    buttonRef,
    iconRef,
    wrapperRef,
    dispatch
  } = props

  return (
    <div styleName="start-discovering-podcasts-consent-overlay" ref={wrapperRef}>
      <div styleName="start-discovering-podcasts-consent-overlay-inner" ref={buttonRef}>
        <button
          styleName="start-discovering-consent-button"
          onClick={() => {
            if (!discoverPodcastsLoaded) return
            onStartDiscoveringPodcast(true)
            // waiting animations to finish
            setTimeout(() => {
              // todo: reason why this is here is because of "safari user initiated autoplay"
              // todo: look into how to make it more robust/better
              const player = AudioPlayerService.getPlayer('discover-podcasts')
              player && player.play(playerActiveIndex)
              dispatch(action_playerPlay('discover-podcasts'))
            }, 400)
          }}
        >
          <span styleName="start-discovering-consent-button--icon" ref={iconRef}>
            <IconPlay />
          </span>
          <span styleName="start-discovering-consent-button--text">
            {discoverPodcastsLoaded ? 'Aloita klippien kuuntelu' : 'Loading'}
          </span>
        </button>
      </div>
    </div>
  )
})

const StartDiscoveringPodcastsConsentOverlay = props => {
  const { startDiscoveringPodcasts, onStartDiscoveringPodcast, discoverPodcastsLoaded } = props

  const discoverPodcastsLoadedRef = React.useRef(discoverPodcastsLoaded)
  discoverPodcastsLoadedRef.current = discoverPodcastsLoaded

  const wrapperRef = React.useRef()
  const buttonRef = React.useRef()
  const iconRef = React.useRef()

  let ContentElement

  if (startDiscoveringPodcasts) {
    ContentElement = null
  } else {
    ContentElement = (
      <Fade
        key="start-discovering-podcasts"
        timeout={800}
        buttonRef={buttonRef}
        wrapperRef={wrapperRef}
        iconRef={iconRef}
      >
        <Content
          onStartDiscoveringPodcast={onStartDiscoveringPodcast}
          buttonRef={buttonRef}
          wrapperRef={wrapperRef}
          iconRef={iconRef}
        />
      </Fade>
    )
  }

  return <TransitionGroup>{ContentElement}</TransitionGroup>
}

export { StartDiscoveringPodcastsConsentOverlay }
