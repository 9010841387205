// npm
import * as React from 'react'
import { TimelineMax } from 'gsap'

interface IProps {
  audioPlayerState: string
}

// styles
import './ClipSplash.scss'

const ClipAudioWaves = React.memo((props: IProps) => {
  const { audioPlayerState } = props

  // refs
  const ItemsElementRef = React.useRef()
  const isMounted = React.useRef(true)
  const tlRef = React.useRef(new TimelineMax())
  const audioPlayerStateRef = React.useRef(audioPlayerState)

  const getRandomLength = function(minimum, maximum, precision) {
    precision = precision === undefined ? 0 : precision

    const random = Math.random() * (maximum - minimum) + minimum

    return Number(random.toFixed(precision))
  }

  const fakeAudioWaves = (tl, ChildItemElements) => {
    if (audioPlayerStateRef.current !== 'play') return

    ChildItemElements.forEach(Ele => {
      tl.to(
        Ele,
        getRandomLength(0.1, 0.25, 1),
        {
          y: `${Math.floor(Math.random() * Math.floor(60))}%`,

          ease: 'circ.out',
          onComplete: () => {
            if (!isMounted.current) return

            tl.clear()
            fakeAudioWaves(tl, ChildItemElements)
          }
        },
        0
      )
    })
  }

  React.useEffect(
    () => {
      if (audioPlayerStateRef.current === audioPlayerState) return
      audioPlayerStateRef.current = audioPlayerState

      const tl = tlRef.current

      const ItemsElement: HTMLElement = ItemsElementRef.current
      if (!ItemsElement || !ItemsElement.children || !ItemsElement.children.length) return null

      const ChildItemElements = [].slice.call(ItemsElement.children)

      if (audioPlayerState === 'stop') {
        tlRef.current.pause()
      } else if (audioPlayerState === 'play') {
        tl.kill()
        tlRef.current.play(0, false)
        fakeAudioWaves(tl, ChildItemElements)
      }
    },
    [audioPlayerState]
  )

  React.useEffect(() => {
    if (audioPlayerState === 'play') {
      const tl = tlRef.current

      const ItemsElement: HTMLElement = ItemsElementRef.current
      if (!ItemsElement || !ItemsElement.children || !ItemsElement.children.length) return null

      const ChildItemElements = [].slice.call(ItemsElement.children)

      fakeAudioWaves(tl, ChildItemElements)
    }

    return () => {
      const tl = tlRef.current

      isMounted.current = false
      tl.pause(0, true)
      tl.kill()
    }
  }, [])

  return (
    <div styleName="clip-splash--audio-waves--wrapper">
      <div styleName="clip-splash--audio-waves--items" ref={ItemsElementRef}>
        <div styleName="clip-splash--audio-waves--item" />
        <div styleName="clip-splash--audio-waves--item" />
        <div styleName="clip-splash--audio-waves--item" />
        <div styleName="clip-splash--audio-waves--item" />
        <div styleName="clip-splash--audio-waves--item" />
        <div styleName="clip-splash--audio-waves--item" />
        <div styleName="clip-splash--audio-waves--item" />
        <div styleName="clip-splash--audio-waves--item" />
        <div styleName="clip-splash--audio-waves--item" />
        <div styleName="clip-splash--audio-waves--item" />
        <div styleName="clip-splash--audio-waves--item" />
        <div styleName="clip-splash--audio-waves--item" />
      </div>
    </div>
  )
})

export { ClipAudioWaves }
