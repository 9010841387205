// npm
import * as React from 'react'
import { connect } from 'react-redux'

// selectors
import { selector_activePodcastInfo } from '@/modules/yleDiscoverPodcasts/selectors/selector_activePodcastInfo'

// components
import { Button01 } from '@/Components/LinkButton01/Button01'

// icons
import { IconInfo } from '@/assets/ReactIcons/IconInfo'

// typescript
import { IReduxStore } from '@/App/redux/loadReducers'

// styles
import './ClipActions.scss'
import { IconYle } from '@/assets/ReactIcons/IconYle'

const mapStateToProps = (state: IReduxStore) => {
  const activePodcastInfo = selector_activePodcastInfo(state)

  return {
    activePodcastInfo
  }
}

const ClipActions = connect(mapStateToProps)((props: any) => {
  const {
    className,
    activePodcastInfo,
    onToggleOpenMorePodcastInfo,
    onToggleBeforeContinueToFullPodcastOverlay
  } = props

  return (
    <div className={className} styleName="clip-splash--item--actions">
      <div styleName="clip-splash--item--actions-inner">
        {/* <button onClick={onToggleOpenMorePodcastInfo} styleName="
        _info-icon">
          <IconInfo />
        </button> */}
        <div styleName="clip-splash--item--actions-item">
          <Button01 IconElement={IconInfo} onClick={onToggleOpenMorePodcastInfo} />
        </div>
        <div styleName="clip-splash--item--actions-item">
          <Button01
            label="Kuuntele koko jakso Areenassa"
            onClick={() => {
              onToggleBeforeContinueToFullPodcastOverlay({
                href: `https://areena.yle.fi/audio/${activePodcastInfo.yleId}`
              })
            }}
            IconElement={IconYle}
          />
        </div>
      </div>
    </div>
  )
})

export { ClipActions }
