// npm
import * as React from 'react'

// components
import { Section } from '@/Components/Section/Section'
import { DiscoverPodcasts } from '@/Components/PageDiscoverPodcasts/DiscoverPodcasts'
import { DiscoverPodcastsEndReachedInfo } from '@/Components/PageDiscoverPodcasts/DiscoverPodcastsEndReachedInfo/DiscoverPodcastsEndReachedInfo'
import { BeforeContinueToFullPodcastOverlay } from '@/Components/PageDiscoverPodcasts/BeforeContinueToFullPodcastOverlay/BeforeContinueToFullPodcastOverlay'
import { ClipInfoOverlay } from '@/Components/PageDiscoverPodcasts/ClipInfoOverlay/ClipInfoOverlay'

// styles
import './PageDiscoverPodcasts.scss'
import { StartDiscoveringPodcastsConsentOverlay } from '@/Components/PageDiscoverPodcasts/StartDiscoveringPodcastsConsentOverlay/StartDiscoveringPodcastsConsentOverlay'

const PageDiscoverPodcasts = () => {
  let showStartDiscoveringPodcastsOverlay = false
  if (process.env.STAGE === 'dev-local') {
    // disable in dev-local stage
    showStartDiscoveringPodcastsOverlay = false
  }

  const [startDiscoveringPodcasts, onStartDiscoveringPodcast] = React.useState(showStartDiscoveringPodcastsOverlay)
  const [isOpenMorePodcastInfo, onOpenMorePodcastInfo] = React.useState(false)
  const [discoverPodcastsEndReached, onDiscoverPodcastsEndReached] = React.useState(false)
  const [isToggleBeforeContinueToFullPodcastOverlay, onToggleBeforeContinueToFullPodcastOverlay] = React.useState(false)

  const onToggleOpenMorePodcastInfo = () => {
    onOpenMorePodcastInfo(!isOpenMorePodcastInfo)
  }
  const onToggleDiscoverPodcastsEndReached = () => {
    onDiscoverPodcastsEndReached(!discoverPodcastsEndReached)
  }

  return (
    <>
      <ClipInfoOverlay
        isOpenMorePodcastInfo={isOpenMorePodcastInfo}
        onToggleOpenMorePodcastInfo={onToggleOpenMorePodcastInfo}
        onDiscoverPodcastsEndReached={onDiscoverPodcastsEndReached}
        onToggleDiscoverPodcastsEndReached={onToggleDiscoverPodcastsEndReached}
      />

      <DiscoverPodcastsEndReachedInfo
        discoverPodcastsEndReached={discoverPodcastsEndReached}
        onToggleDiscoverPodcastsEndReached={onToggleDiscoverPodcastsEndReached}
      />

      <BeforeContinueToFullPodcastOverlay
        isToggleBeforeContinueToFullPodcastOverlay={isToggleBeforeContinueToFullPodcastOverlay}
        onToggleBeforeContinueToFullPodcastOverlay={onToggleBeforeContinueToFullPodcastOverlay}
        onStartDiscoveringPodcast={onStartDiscoveringPodcast}
      />

      <StartDiscoveringPodcastsConsentOverlay
        startDiscoveringPodcasts={startDiscoveringPodcasts}
        onStartDiscoveringPodcast={onStartDiscoveringPodcast}
      />

      <Section styleName="page-discover-podcasts--section">
        <DiscoverPodcasts
          startDiscoveringPodcasts={startDiscoveringPodcasts}
          onToggleDiscoverPodcastsEndReached={onToggleDiscoverPodcastsEndReached}
          onToggleOpenMorePodcastInfo={onToggleOpenMorePodcastInfo}
          onToggleBeforeContinueToFullPodcastOverlay={onToggleBeforeContinueToFullPodcastOverlay}
        />
      </Section>
    </>
  )
}

export { PageDiscoverPodcasts }
