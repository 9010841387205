// npm
import * as React from 'react'
import styled from 'styled-components'

// styles
import './LinkButton01.scss'

interface IProps {
  title: string
  label?: string
  IconElement?: any
  href?: string
  onClick?: (e?) => void
  iconWidth?: string
  target?: '_blank' | '_self' | '_parent' | '_top'
}

const IconWrapperStyled = styled.span`
  ${props => `
    svg {
      width: ${props.iconWidth || '100%'};
    }
  `}
`

const Link01 = (props: IProps) => {
  const { target, label, onClick, href, title, IconElement, iconWidth } = props

  if (!href && !onClick) return null
  if (!IconElement && !label) return null

  const iconOnly = IconElement && !label

  const linkTarget = target || '_parent'

  return (
    <a
      href={href}
      onClick={e => onClick && onClick(e)}
      target={linkTarget}
      title={title}
      styleName={iconOnly ? 'linkbutton-01-wrapper_icon-only' : 'linkbutton-01-wrapper'}
    >
      {IconElement && (
        <IconWrapperStyled styleName="linkbutton-01-icon" iconWidth={iconWidth}>
          <IconElement />
        </IconWrapperStyled>
      )}

      <span styleName="linkbutton-01-label">{label}</span>
    </a>
  )
}

export { Link01 }
