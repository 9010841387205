import * as React from 'react'
import { connect } from 'react-redux'

// services
import { AudioPlayerService } from '../service/AudioPlayerService'

// selectors
import { selector_audioPlayerInfo } from '../selectors/selector_audioPlayerInfo'

import { IplayerItem, IplayerState, IaudioPlayerInfo } from '../redux/typescript'
import { selector_audioPlayerActiveIndex } from '../selectors/selector_audioPlayerActiveIndex'

type PlayerProps = {
  player: IplayerItem
  audioPlayerInfo: IaudioPlayerInfo
  audioPlayerActiveIndex: false
}
interface IProps extends PlayerProps {
  dispatch: () => void
  runAudioEnded: () => void
  playState: IplayerState
  other?: string
  activePlaylistIndex: number
}

const mapStateToProps = (state, receivedProps: PlayerProps) => {
  return {
    audioPlayerInfo: selector_audioPlayerInfo(state, receivedProps.player.playerId),
    audioPlayerActiveIndex: selector_audioPlayerActiveIndex(state, receivedProps.player.playerId)
  }
}

const AudioPlayerHeadless = connect(mapStateToProps)(
  (props: IProps): React.ReactElement => {
    // props
    const {
      player: createPlayerProps,
      audioPlayerInfo,
      audioPlayerActiveIndex,
      runAudioEnded,
      activePlaylistIndex,
      dispatch
    } = props

    // audioPlayerInfo
    const { playerState, playerActiveIndex, playerId } = audioPlayerInfo

    React.useEffect(
      () => {
        if (typeof activePlaylistIndex !== 'number' || activePlaylistIndex < 0) {
          return
        }
        const player = AudioPlayerService.getPlayer(playerId)

        player && player.setPlayerIndex(activePlaylistIndex)
      },
      [activePlaylistIndex]
    )

    // setup
    React.useEffect(() => {
      if (!AudioPlayerService.isPlayerExists(createPlayerProps.playerId)) {
        AudioPlayerService.createPlayer({
          playerId: createPlayerProps.playerId,
          playerActiveIndex: createPlayerProps.playerActiveIndex,
          playerPlaylist: createPlayerProps.playerPlaylist,
          onAudioEnded: () => {
            runAudioEnded()
          }
        })(dispatch)
      }
    }, [])

    React.useEffect(
      () => {
        if (!audioPlayerInfo.playerId) return
        const player = AudioPlayerService.getPlayer(playerId)

        if (!player) return

        if (playerState === 'play') {
          player.play(playerActiveIndex)
        }
        if (playerState === 'stop') {
          player.stop()
        }
      },
      [playerState]
    )

    React.useEffect(
      () => {
        if (!audioPlayerInfo.playerId) return
        const player = AudioPlayerService.getPlayer(playerId)

        if (!player) return

        if (playerState === 'play') {
          player.play(playerActiveIndex)
        }
        if (playerState === 'stop') {
          player.stop()
        }
      },
      [audioPlayerActiveIndex]
    )

    return null
  }
)

export { AudioPlayerHeadless }
