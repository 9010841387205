import * as React from 'react'
import { createStore } from 'redux'
import { Provider } from 'react-redux'

// App redux store reducers
import { loadReducers } from './loadReducers'
import { appReduxComposedEnhancers } from './composedEnhancers'

// redux store initial state
const reduxInitialState = undefined

const appReduxStore = createStore(loadReducers, reduxInitialState, appReduxComposedEnhancers)

const AppReduxProvider = ({ children }) => {
  return <Provider store={appReduxStore}> {children} </Provider>
}

export { AppReduxProvider }
