import { applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'

// app redux middlewares
const reduxMiddlewares = [thunk]

const reduxMiddlewareEnhancer = applyMiddleware(...reduxMiddlewares)
const redixEnhancers = [reduxMiddlewareEnhancer]
const appReduxComposedEnhancers =
  process.env.ENV !== 'prod' ? composeWithDevTools(...redixEnhancers) : reduxMiddlewareEnhancer

export { appReduxComposedEnhancers }
