// npm
import * as React from 'react'
import { connect } from 'react-redux'

// selectors
import { selector_pickPodcastsToDiscover } from '@/modules/yleDiscoverPodcasts/selectors/selector_pickPodcastsToDiscover'
import { selector_pickPodcastToDiscoverAudioSrc } from '@/modules/yleDiscoverPodcasts/selectors/selector_pickPodcastToDiscoverAudioSrc'
import { selector_activePodcastInfo } from '@/modules/yleDiscoverPodcasts/selectors/selector_activePodcastInfo'
import { selector_discoverPodcastsLoaded } from '@/modules/yleDiscoverPodcasts/selectors/selector_discoverPodcastsLoaded'
import { selector_discoverPodcastsLoading } from '@/modules/yleDiscoverPodcasts/selectors/selector_discoverPodcastsLoading'
import { selector_discoverPodcastsError } from '@/modules/yleDiscoverPodcasts/selectors/selector_discoverPodcastsError'
import { selector_audioPlayerState } from '@/modules/audioPlayer/selectors/selector_audioPlayerState'
import { selector_isAudioPlayerAutoplay } from '@/modules/audioPlayer/selectors/selector_isAudioPlayerAutoplay'

// actions
import { action_playerPlay, action_playerStop } from '@/modules/audioPlayer/redux/actions'
import {
  action_goNextActivePodcast,
  action_goPreviousActivePodcast,
  action_fetchDiscoverPodcasts,
  action_endReached
} from '@/modules/yleDiscoverPodcasts/redux/actions'

// components
import { AudioPlayerHeadless } from '@/modules/audioPlayer/Components/AudioPlayerHeadless'
import { ClipSplash } from '@/Components/PageDiscoverPodcasts/ClipSplash/ClipSplash'
import { ClipPaginationIndicators } from '@/Components/PageDiscoverPodcasts/ClipPaginationIndicators/ClipPaginationIndicators'
import { ClipSplashSlideshowCarousel } from './ClipSplashSlideshowCarousel/ClipSplashSlideshowCarousel'
import { ClipActions } from './ClipActions/ClipActions'

// typescript
import { IReduxStore } from '@/App/redux/loadReducers'

// styles
import './DiscoverPodcasts.scss'

const mapStateToProps = (state: IReduxStore) => {
  const isAudioPlayerAutoplay = selector_isAudioPlayerAutoplay(state)
  const activePodcastInfo = selector_activePodcastInfo(state)
  const podcastsToDiscover = selector_pickPodcastsToDiscover(state)
  const discoverPodcastsLoaded = selector_discoverPodcastsLoaded(state)
  const discoverPodcastsLoading = selector_discoverPodcastsLoading(state)
  const discoverPodcastsError = selector_discoverPodcastsError(state)
  const pickPodcastToDiscoverAudioSrc = selector_pickPodcastToDiscoverAudioSrc(state)
  const audioPlayerState = selector_audioPlayerState(state)

  return {
    isAudioPlayerAutoplay,
    activePodcastInfo,
    activePodcastIndex: activePodcastInfo.index,
    activePodcastId: activePodcastInfo.yleId,
    podcastsToDiscover,
    discoverPodcastsLoaded,
    discoverPodcastsLoading,
    discoverPodcastsError,
    pickPodcastToDiscoverAudioSrc,
    audioPlayerState
  }
}

interface IPropsDiscoverPodcastsPrevNext {
  onClick: () => void
  SlideshowItemsRef: React.MutableRefObject<HTMLElement>
}

const DiscoverPodcasts = connect(mapStateToProps)((props: any) => {
  const {
    dispatch,
    discoverPodcastsLoaded,
    activePodcastIndex,
    activePodcastId,
    pickPodcastToDiscoverAudioSrc,
    podcastsToDiscover,
    audioPlayerState,
    startDiscoveringPodcasts,
    onToggleDiscoverPodcastsEndReached,
    onToggleOpenMorePodcastInfo,
    onToggleBeforeContinueToFullPodcastOverlay,
    isAudioPlayerAutoplay
  } = props

  const activePodcastIndexRef: React.MutableRefObject<number> = React.useRef(activePodcastIndex)
  activePodcastIndexRef.current = activePodcastIndex

  const isAudioPlayerAutoplayRef: React.MutableRefObject<number> = React.useRef(isAudioPlayerAutoplay)
  isAudioPlayerAutoplayRef.current = isAudioPlayerAutoplay

  React.useEffect(() => {
    if (!discoverPodcastsLoaded && !discoverPodcastsLoaded) {
      dispatch(action_fetchDiscoverPodcasts())
    }
  }, [])

  const onGoNextActivePodcast = (userInitiated?: boolean) => {
    if (!isAudioPlayerAutoplayRef.current) {
      dispatch(action_playerStop('discover-podcasts'))
      return
    }

    if (activePodcastIndexRef.current === pickPodcastToDiscoverAudioSrc.length - 1) {
      dispatch(action_playerStop('discover-podcasts'))
      onToggleDiscoverPodcastsEndReached()
      setTimeout(() => {
        // slight delay to reset playlist index. (due to animations, otherwise too much going on at the same time)
        dispatch(action_endReached())
      }, 500)
    } else {
      dispatch(action_goNextActivePodcast(userInitiated))
    }
  }

  const onGoToPreviousItem = (userInitiated?: boolean) => dispatch(action_goPreviousActivePodcast(userInitiated))

  const onTouchStartRun = () => {
    dispatch(action_playerStop('discover-podcasts'))
  }

  const onTouchEndRun = () => {
    dispatch(action_playerPlay('discover-podcasts'))
  }

  return (
    <>
      {activePodcastIndex > -1 && (
        <AudioPlayerHeadless
          player={{
            playerId: 'discover-podcasts',
            playerActiveIndex: activePodcastIndex,
            playerPlaylist: pickPodcastToDiscoverAudioSrc
          }}
          runAudioEnded={() => {
            onGoNextActivePodcast()
          }}
          activePlaylistIndex={activePodcastIndex}
        />
      )}

      <div styleName={startDiscoveringPodcasts ? 'clip-splash-items' : 'clip-splash-items_inactive'}>
        <div styleName="clip-splash--item--pagination">
          <ClipPaginationIndicators />
        </div>

        <div styleName="clip-splash--item--content">
          {(podcastsToDiscover && podcastsToDiscover.length && (
            <ClipSplashSlideshowCarousel
              activeItem={activePodcastIndex}
              onGoToNextItem={onGoNextActivePodcast}
              onGoToPreviousItem={onGoToPreviousItem}
              onTouchStartRun={onTouchStartRun}
              onTouchEndRun={onTouchEndRun}
            >
              {podcastsToDiscover.map(podcastInfo => {
                return (
                  <ClipSplash
                    podcastInfo={podcastInfo}
                    key={podcastInfo.yleId}
                    isActive={podcastInfo.yleId === activePodcastId}
                    audioPlayerState={audioPlayerState}
                  />
                )
              })}
            </ClipSplashSlideshowCarousel>
          )) || <ClipSplash podcastInfo={{}} />}
        </div>

        <ClipActions
          onToggleOpenMorePodcastInfo={onToggleOpenMorePodcastInfo}
          onToggleBeforeContinueToFullPodcastOverlay={() => {
            dispatch(action_playerStop('discover-podcasts'))
            onToggleBeforeContinueToFullPodcastOverlay(true)
          }}
          styleName="clip-splash--item"
        />
      </div>
    </>
  )
})

export { DiscoverPodcasts }
