// npm
import * as React from 'react'
import { connect } from 'react-redux'

// selectors
import { selector_discoverPodcastsLoading } from '@/modules/yleDiscoverPodcasts/selectors/selector_discoverPodcastsLoading'
import { selector_discoverPodcastsError } from '@/modules/yleDiscoverPodcasts/selectors/selector_discoverPodcastsError'

// components
import { ClipSplashImage } from './ClipSplashImage'
import { ClipSplashBackground } from './ClipSplashBackground'
import { ClipAudioWaves } from './ClipAudioWaves'
import { ClipSplashInfo } from './ClipSplashInfo'

// typescript
import { IReduxStore } from '@/App/redux/loadReducers'
import { IpodcastInfo } from '@/modules/yleDiscoverPodcasts/redux/typescript'

interface IProps {
  podcastInfo: IpodcastInfo
  discoverPodcastsLoading: boolean
  discoverPodcastsError: boolean
  isActive: boolean
  slideshowHide: boolean
  audioPlayerState: string
}

// styles
import './ClipSplash.scss'

const mapStateToProps = (state: IReduxStore) => {
  const discoverPodcastsLoading = selector_discoverPodcastsLoading(state)
  const discoverPodcastsError = selector_discoverPodcastsError(state)

  return {
    discoverPodcastsLoading,
    discoverPodcastsError
  }
}
const ClipSplash = connect(
  mapStateToProps,
  null
)(
  React.memo((props: IProps) => {
    const { podcastInfo, isActive, audioPlayerState } = props
    // loading states
    const { discoverPodcastsLoading, discoverPodcastsError, slideshowHide } = props
    // content

    const { episodeTitle, episodeName, thumbnailSrc } = podcastInfo

    return (
      <div styleName="clip-splash--wrapper">
        <div styleName="clip-splash--content" style={{ ...(slideshowHide ? { display: 'none' } : {}) }}>
          <section styleName="clip-splash--content-inner">
            <ClipSplashImage discoverPodcastsLoading={discoverPodcastsLoading} thumbnailSrc={thumbnailSrc} />
            <ClipSplashInfo
              episodeTitle={episodeTitle}
              episodeName={episodeName}
              discoverPodcastsLoading={discoverPodcastsLoading}
              discoverPodcastsError={discoverPodcastsError}
            />
          </section>
          {isActive && <ClipAudioWaves audioPlayerState={audioPlayerState} />}
          <ClipSplashBackground discoverPodcastsLoading={discoverPodcastsLoading} thumbnailSrc={thumbnailSrc} />
        </div>
      </div>
    )
  })
)

export { ClipSplash }
