// npm
import * as React from 'react'
import { TweenMax, TimelineMax } from 'gsap'

interface IProps {
  activeItem: number
  children: React.ReactElement
  onGoToNextItem: (userInitiated?: boolean) => void
  onGoToPreviousItem: (userInitiated?: boolean) => void
  onTouchStartRun: () => void
  onTouchEndRun: () => void
  onNewActiveItem: () => void
}

const SlideshowCarouselTouch = React.forwardRef((props: IProps, ref: React.MutableRefObject<HTMLDivElement>) => {
  const {
    activeItem,
    children,
    onNewActiveItem,
    onGoToNextItem,
    onGoToPreviousItem,
    onTouchStartRun,
    onTouchEndRun
  } = props

  const SlideshowItemsElement: HTMLDivElement = ref.current
  const touchStartCssTranslateXPixelValueRef: React.MutableRefObject<number> = React.useRef(0)
  const cssTranslateXPixelValueRef: React.MutableRefObject<number> = React.useRef(0)
  const touchStartXRef: React.MutableRefObject<number> = React.useRef(0)
  // determines if user is swiping touch x axis
  const isXTouchSwipe: React.MutableRefObject<boolean> = React.useRef(false)
  // set true once user is has started swiping X axis and is active.
  const isXTouchSwipeStarted: React.MutableRefObject<boolean> = React.useRef(false)

  // last item pos
  const lastItemIndex: React.MutableRefObject<number> = React.useRef(-1)

  const TimelineRef: React.MutableRefObject<any> = React.useRef(new TimelineMax())

  // initial mount
  const initMount: React.MutableRefObject<boolean> = React.useRef(false)

  React.useEffect(
    () => {
      const tl = TimelineRef.current

      if (!SlideshowItemsElement) return
      if (!initMount.current) {
        initMount.current = true
        TweenMax.set(SlideshowItemsElement, { x: `${activeItem * -100}%`, z: 0.01 })
        return
      }
      tl.clear()
      tl.to(SlideshowItemsElement, 0.5, { x: `${activeItem * -100}%`, z: 0.01, ease: 'back.out(0.9)' }, 0)
      tl.play(0)

      onNewActiveItem && onNewActiveItem()
    },
    [activeItem]
  )

  const onTouchStart = (e: React.TouchEvent) => {
    const tl = TimelineRef.current
    const { pageX } = e.touches[0]

    onTouchStartRun && onTouchStartRun()

    const SlideshowItemsElementChildren = SlideshowItemsElement.children
    lastItemIndex.current = SlideshowItemsElementChildren.length - 1

    // get and calculate positions
    const width100PrecentToPixel = SlideshowItemsElement.offsetWidth
    const styleTransform = SlideshowItemsElement.style.transform
    const cssTranslateXPrecentageValue = !styleTransform
      ? 0
      : Number(styleTransform.match(/translate?.+\(([-]?\d*\.?\d*)%/)[1])
    const width100PrecentageAsPixel = width100PrecentToPixel / 100
    const cssTranslateXPixelValue = width100PrecentageAsPixel * cssTranslateXPrecentageValue

    // update references
    touchStartXRef.current = pageX
    touchStartCssTranslateXPixelValueRef.current = cssTranslateXPixelValue
    cssTranslateXPixelValueRef.current = cssTranslateXPixelValue

    // tl.pause()
    TweenMax.set(SlideshowItemsElement, { x: `${String(cssTranslateXPixelValue)}px` }, 0)
    tl.pause()
  }

  const onTouchMove = (e: React.TouchEvent) => {
    const { pageX: touchCurrentX } = e.touches[0]

    const oldCssTranslateXValue = touchStartCssTranslateXPixelValueRef.current
    const oldTouchXValue = touchStartXRef.current

    const newTouchXValue = touchCurrentX - oldTouchXValue
    const newCssTranslateXPixelValue = oldCssTranslateXValue + newTouchXValue

    // tl.pause()
    // don't do much if not enough x movement has applied
    if (Math.abs(newTouchXValue) < 15 && !isXTouchSwipeStarted.current) return

    isXTouchSwipe.current = true
    // run once if started swiping
    if (!isXTouchSwipeStarted.current) {
      isXTouchSwipeStarted.current = true
    }

    // limit, restrict how much can item be swiped X
    if (SlideshowItemsElement.offsetWidth > Math.abs(newTouchXValue) - 50) {
      // remove css transition when doing touch
      cssTranslateXPixelValueRef.current = newCssTranslateXPixelValue
      TweenMax.set(SlideshowItemsElement, { x: `${String(newCssTranslateXPixelValue)}px` }, 0)
      // limit, restrict how much can item be swiped X
      return
    }
  }

  const onTouchEnd = () => {
    const tl = TimelineRef.current

    const width100PrecentInPixels = SlideshowItemsElement.offsetWidth
    const startPixelsToPrecentage = -(activeItem * 100)
    const currentPixelsToPrecentage = Math.round((cssTranslateXPixelValueRef.current / width100PrecentInPixels) * 100)

    isXTouchSwipe.current = false
    isXTouchSwipeStarted.current = false

    const diffStartendPrecentage = startPixelsToPrecentage - currentPixelsToPrecentage

    // set pixels values to precentage
    TweenMax.set(SlideshowItemsElement, { x: `${String(currentPixelsToPrecentage)}%` })
    tl.play()

    if (diffStartendPrecentage > 20) {
      // swiping to right item
      if (lastItemIndex.current === activeItem) {
        tl.clear()
        tl.to(SlideshowItemsElement, 0.4, { x: `${activeItem * -100}%`, ease: 'back.out(2)' }, 0)
        tl.play()
      } else {
        onTouchEndRun && onTouchEndRun()
      }
      onGoToNextItem(true)
      return
    } else if (diffStartendPrecentage < -20) {
      // swiping to left item
      if (0 === activeItem) {
        tl.clear()
        tl.to(SlideshowItemsElement, 0.4, { x: `${activeItem * -100}%`, ease: 'back.inOut(2)' }, 0)
        tl.play()
        return
      }
      onGoToPreviousItem(true)
      onTouchEndRun && onTouchEndRun()
    } else {
      // not swiping enough to trigger next/prev item
      tl.clear()
      tl.to(SlideshowItemsElement, 0.4, { x: `${activeItem * -100}%`, ease: 'back.inOut(2)' }, 0)
      tl.play()
      onTouchEndRun && onTouchEndRun()
    }
  }

  return React.cloneElement(children, { onTouchStart, onTouchMove, onTouchEnd })
})

export { SlideshowCarouselTouch }
