// npm
import * as React from 'react'

// typescript
interface IProps {
  discoverPodcastsLoading: boolean
  discoverPodcastsError: boolean
  episodeTitle: string
  episodeName: string
}

// styles
import './ClipSplash.scss'

const ClipSplashInfo = React.memo((props: IProps) => {
  // loading states
  const { discoverPodcastsLoading, discoverPodcastsError } = props
  // content
  const { episodeTitle, episodeName } = props

  const getInfoContent = () => {
    if (discoverPodcastsLoading) {
      return <div>Ladataan</div>
    }

    if (discoverPodcastsError) {
      return <div>Virhe</div>
    }

    if (!episodeTitle) {
      return <p>No data</p>
    }

    return (
      <>
        <h2>{episodeTitle || 'Title'}</h2>
        <p>{episodeName || ''}</p>
      </>
    )
  }

  return <article styleName="clip-splash--content--item--info">{getInfoContent()}</article>
})

export { ClipSplashInfo }
