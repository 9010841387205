// npm
import * as React from 'react'

// components
import { SlideshowCarouselTouch } from './SlideshowCarouselTouch/SlideshowCarouselTouch'

// styles
import './ClipSplashSlideshowCarousel.scss'

// hides clips to improve performance
const shouldHideStyle = (itemIndex, activeItemIndex) => {
  // items to render at a time to left/prev and right/next, this includes "middle/current" item.
  const itemsToShowLeft = 3
  const itemsToShowRight = 4 // minus 1 item if counting middle item (4 = 3 to right)
  if (itemIndex > activeItemIndex - itemsToShowLeft && itemIndex < activeItemIndex + itemsToShowRight) {
    return false
  }

  return true
}

const ClipSplashSlideshowCarousel = props => {
  const {
    activeItem: activeItemIndex,
    children,
    onGoToNextItem,
    onNewActiveItem,
    onGoToPreviousItem,
    onTouchStartRun,
    onTouchEndRun
  } = props

  const SlideshowItemsRef = React.useRef()

  return (
    <SlideshowCarouselTouch
      ref={SlideshowItemsRef}
      activeItem={activeItemIndex}
      onGoToNextItem={onGoToNextItem}
      onGoToPreviousItem={onGoToPreviousItem}
      onTouchStartRun={onTouchStartRun}
      onTouchEndRun={onTouchEndRun}
      onNewActiveItem={onNewActiveItem}
    >
      <div styleName="slideshow-wrapper">
        <button styleName="clip-splash--previous-button" onClick={() => onGoToPreviousItem(true)}>
          Listen previous podcast.
        </button>
        <div styleName="slideshow-items" ref={SlideshowItemsRef}>
          {children.length > 1 &&
            children.map((child, index) => {
              const isActiveItem = index === activeItemIndex

              return (
                <div
                  styleName="slideshow-item"
                  style={{ opacity: isActiveItem ? 1 : 0.2, ...(isActiveItem ? { transform: 'scale(1)' } : {}) }}
                  key={child.key}
                >
                  {React.cloneElement(child, {
                    slideshowHide: shouldHideStyle(index, activeItemIndex)
                  })}
                </div>
              )
            })}
        </div>
        <button styleName="clip-splash--next-button" onClick={() => onGoToNextItem(true)}>
          Listen previous podcast.
        </button>
      </div>
    </SlideshowCarouselTouch>
  )
}

export { ClipSplashSlideshowCarousel }
