import * as React from 'react'

const IconOpenExternal = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
      <path
        fill="#fff"
        d="M42.67 42.67H5.33V5.33H24V0H5.33A5.33 5.33 0 000 5.33v37.34A5.33 5.33 0 005.33 48h37.34A5.35 5.35 0 0048 42.67V24h-5.33zM29.33 0v5.33h9.58L12.69 31.55l3.76 3.76L42.67 9.09v9.58H48V0z"
      />
    </svg>
  )
}

export { IconOpenExternal }
