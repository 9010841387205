import * as React from 'react'
import styled from 'styled-components'

const SectionStyled = styled.section`
  ${({ noPadding }) => {
    return `
      padding: ${noPadding ? 0 : '1rem'};
    `
  }}
`

const Section = ({ children, ...args }) => {
  return <SectionStyled {...args}>{children}</SectionStyled>
}

export { Section }
