import * as React from 'react'

// styles
import './LayoutDefault.scss'

const LayoutDefault = ({ children }) => {
  return (
    <div styleName="layout-wrapper">
      <div styleName="layout-inner">
        <div styleName="layout-content">{children}</div>
      </div>
    </div>
  )
}

export { LayoutDefault }
