import { action_createNewPlayer, action_setPlayerIndex } from '../redux/actions'
import { YTMLog } from '@/modules/yleTagManager/yleTagManager'
interface IcreatePlayerArgs {
  playerId: string
  playerActiveIndex: number
  playerPlaylist: string[]
  onAudioEnded: () => void
}

const AudioAPI = new Audio()
AudioAPI.preload = 'auto'

AudioAPI.defaultPlaybackRate = 1
AudioAPI.volume = 1

const AudioPlayerService = src => {
  AudioAPI.src = src
}

let _src = null

// player store
const playersCollection = {}

// dispatch
let dispatch

const listenPlayerTickCollection = {}
// todo: stop listening
export const listenPlayerTick = playerId => {
  return playerId
}

const createCustomEvent = (event, detail, playerId) => {
  if (!listenPlayerTickCollection[playerId]) {
    listenPlayerTickCollection[playerId] = {}
  }

  listenPlayerTickCollection[playerId] = new CustomEvent(`${playerId}::${event}`, {
    detail
  })

  return listenPlayerTickCollection[playerId]
}

// initial player setup
AudioPlayerService.createPlayer = (args: IcreatePlayerArgs) => reduxDispatch => {
  // todo: remove onAudioEnded
  const { playerId, playerPlaylist, playerActiveIndex, onAudioEnded } = args

  if (playersCollection[playerId]) return
  if (!dispatch) dispatch = reduxDispatch

  AudioAPI.onloadstart = () => {
    // todo: this is hardcoded analytics. to separate into different logic.
    const getYleID = AudioAPI.src.match(/audio\/(.+)_clip/)
    if (!getYleID || !getYleID[1]) {
      console.warn('audio event "clipLoading" received an unexpected video name (yle id not found)', AudioAPI.src)
      return
    }

    YTMLog({ clipLoading: { yle_id: getYleID[1] } })
  }
  AudioAPI.onplaying = () => {
    // todo: this is hardcoded analytics. to separate into different logic.
    const getYleID = AudioAPI.src.match(/audio\/(.+)_clip/)
    if (!getYleID || !getYleID[1]) {
      console.warn('audio event "clipPlaying" received an unexpected video name (yle id not found)', AudioAPI.src)
      return
    }

    YTMLog({ clipPlaying: { yle_id: getYleID[1] } })
  }
  AudioAPI.onended = onAudioEnded
  AudioAPI.ontimeupdate = () => {
    const duration = AudioAPI.duration // 20
    const currentTime = AudioAPI.currentTime // 1
    const currentTimePrecentage = (currentTime / duration) * 100

    const customEvent = createCustomEvent('currentTime', { currentTimePrecentage }, playerId)
    document.dispatchEvent(customEvent)
  }

  const minimizedInfo = { wasPaused: true }

  document.addEventListener('visibilitychange', function(event) {
    if (document.visibilityState === 'visible') {
      if (minimizedInfo.wasPaused) return
      AudioAPI.play()
    } else {
      minimizedInfo.wasPaused = AudioAPI.paused
      if (!AudioAPI.paused) {
        AudioAPI.pause()
      }
    }
  })

  playersCollection[playerId] = playerPlaylist

  if (_src !== playerPlaylist[playerActiveIndex]) {
    _src = playerPlaylist[playerActiveIndex]
    AudioAPI.src = _src
  }

  dispatch(
    action_createNewPlayer({
      ...args
    })
  )
}

// check if player exists
AudioPlayerService.isPlayerExists = playerId => {
  return !!playersCollection[playerId]
}

const _AudioPlayer_play = ({ index, player }) => {
  if (_src !== player[index]) {
    _src = player[index]
    AudioAPI.src = _src
  }

  if (AudioAPI.paused) {
    AudioAPI.play()
  }
}

const _AudioPlayer_stop = () => {
  AudioAPI.pause()
}

const _AudioPlayerAction = ({ newIndex, playerId }) => {
  dispatch(
    action_setPlayerIndex({
      playerId,
      newIndex
    })
  )
}

// get player methods
AudioPlayerService.getPlayer = playerId => {
  const player = playersCollection[playerId]
  if (!player) return false

  return {
    playerId,
    play: index => _AudioPlayer_play({ index, player }),
    stop: () => _AudioPlayer_stop(),
    setPlayerIndex: newIndex => _AudioPlayerAction({ playerId, newIndex })
  }
}

AudioPlayerService.player = args => {
  const { playerId } = args

  playersCollection[playerId] = {
    ...args
  }
}

AudioPlayerService.load = src => {
  AudioAPI.src = src
  AudioAPI.load()
}

export { AudioPlayerService }
