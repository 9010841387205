import { createSelector } from 'reselect'

import { IReduxStore } from '@/App/redux/loadReducers'

import { IplayerItem, IaudioPlayerInfo } from '../redux/typescript'

import { selector_audioPlayerState } from './selector_audioPlayerState'

export interface Iselector_audioPlayerInfoOutput {
  isPlaying: boolean
}

const selector_audioPlayerInfo = createSelector(
  (state: IReduxStore, playerId) => {
    const player = state.audioPlayerStore.players[playerId]
    return player
  },
  selector_audioPlayerState,
  (state: IReduxStore) => {
    return state.audioPlayerStore.activePlaylistId
  },
  (state: IReduxStore, playerId) => {
    if (!playerId) return null
    const player = state.audioPlayerStore.players[playerId]
    return player && player.playerActiveIndex
  },
  (player: IplayerItem, playerState, activePlaylistId): IaudioPlayerInfo | false => {
    if (!player || !activePlaylistId) return false

    return {
      ...player,
      playerState,
      activePlaylistId
    }
  }
)

export { selector_audioPlayerInfo }
