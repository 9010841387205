import * as React from 'react'

import { listenPlayerTick } from '@/modules/audioPlayer/service/AudioPlayerService'

function throttle(callback, wait, immediate = false) {
  let timeout = null
  let initialCall = true

  return function() {
    const callNow = immediate && initialCall
    const next = () => {
      callback.apply(this, arguments)
      timeout = null
    }

    if (callNow) {
      initialCall = false
      next()
    }

    if (!timeout) {
      timeout = setTimeout(next, wait)
    }
  }
}

const C = React.memo(({ Component, playerId, ...props }) => {
  const [currentTimePrecentageState, setCurrentTimePrecentageState] = React.useState(0)
  const isMounted = React.useRef(true)

  const onSetCurrentTimePrecentageState = throttle(
    e => {
      if (!isMounted.current) return
      setCurrentTimePrecentageState(e.detail.currentTimePrecentage)
    },
    400,
    true
  )

  React.useEffect(() => {
    // document.addEventListener(`${playerId}::audioReady`, onSetIsAudioReadyState, false)
    document.addEventListener(`${playerId}::currentTime`, onSetCurrentTimePrecentageState, false)

    // remove
    return () => {
      isMounted.current = false
      // document.removeEventListener(`${playerId}::audioReady`, onSetIsAudioReadyState)
      document.removeEventListener(`${playerId}::currentTime`, onSetCurrentTimePrecentageState, false)
    }
  }, [])

  return <Component {...props} currentTimePrecentage={currentTimePrecentageState} />
})

const audioPlayerConnect = playerId => {
  return Component => {
    return props => {
      return <C Component={Component} playerId={playerId} {...props} />
    }
  }
}

listenPlayerTick

export { audioPlayerConnect }
