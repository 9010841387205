import { createSelector } from 'reselect'

import { IReduxStore } from '@/App/redux/loadReducers'

export interface Iselector_audioPlayerInfoOutput {
  isPlaying: boolean
}

const selector_audioPlayerActiveIndex = createSelector(
  (state: IReduxStore, playerId): number => {
    if (!state.audioPlayerStore.players[playerId]) return null
    const player = state.audioPlayerStore.players[playerId]

    return player.playerActiveIndex
  },
  (playerActiveIndex: number): number => {
    return playerActiveIndex
  }
)

export { selector_audioPlayerActiveIndex }
