import { createSelector } from 'reselect'

import { IReduxStore } from '@/App/redux/loadReducers'

import { Iautoplay } from '../redux/typescript'

export interface Iselector_audioPlayerInfoOutput {
  isPlaying: boolean
}

const selector_isAudioPlayerAutoplay = createSelector(
  (state: IReduxStore) => {
    const autoplay = state.audioPlayerStore.autoplay
    return autoplay
  },
  (autoplay): Iautoplay => {
    return autoplay
  }
)

export { selector_isAudioPlayerAutoplay }
