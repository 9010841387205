import { createSelector } from 'reselect'

import { IReduxStore } from '@/App/redux/loadReducers'

import { IplayerState } from '../redux/typescript'

export interface Iselector_audioPlayerInfoOutput {
  isPlaying: boolean
}

const selector_audioPlayerState = createSelector(
  (state: IReduxStore) => {
    const playerState = state.audioPlayerStore.playerState
    return playerState
  },
  (playerState): IplayerState => {
    return playerState
  }
)

export { selector_audioPlayerState }
