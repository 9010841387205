import { STORE_NAME } from '.'

// selectors
import { selector_audioPlayerState } from '../selectors/selector_audioPlayerState'

// constants
export const CREATE_NEW_PLAYER = `${STORE_NAME}::createNewPlayer`
export const SET_PLAYER_INDEX = `${STORE_NAME}::setPlayerIndex`
export const PLAYER_PLAY = `${STORE_NAME}::playerPlay`
export const PLAYER_STOP = `${STORE_NAME}::playerStop`
export const PLAYER_AUTOPLAY = `${STORE_NAME}::playerAutoplay`

export const action_createNewPlayer = payload => {
  return dispatch => {
    const { playerId } = payload

    const players = {
      [playerId]: payload
    }

    dispatch({ type: CREATE_NEW_PLAYER, payload: { players } })
  }
}

export const action_playerPlay = playlistId => {
  return (dispatch, getState) => {
    const state = getState()
    const playerCurrentState = selector_audioPlayerState(state)
    if (playerCurrentState === 'play' || !playlistId) return

    dispatch({ type: PLAYER_PLAY, payload: { playlistId } })
  }
}

export const action_playerStop = playlistId => {
  return (dispatch, getState) => {
    const state = getState()
    const playerCurrentState = selector_audioPlayerState(state)

    if (playerCurrentState === 'stop' || !playlistId) return

    dispatch({ type: PLAYER_STOP, payload: { playlistId } })
  }
}

export const action_playerAutoplay = payload => {
  return { type: PLAYER_AUTOPLAY, payload }
}

export const action_setPlayerIndex = payload => {
  return {
    type: SET_PLAYER_INDEX,
    payload
  }
}
