// npm
import * as React from 'react'
import { connect } from 'react-redux'

// selectors
import { selector_activePodcastInfo } from '@/modules/yleDiscoverPodcasts/selectors/selector_activePodcastInfo'

// components
import { Link01 } from '@/Components/LinkButton01/Link01'

// icons
import { IconOpenExternal } from '@/assets/ReactIcons/IconOpenExternal'

// typescript
import { IReduxStore } from '@/App/redux/loadReducers'

// styles
import './BeforeContinueToFullPodcastOverlay.scss'
import { YTMLog } from '@/modules/yleTagManager/yleTagManager'

const mapStateToProps = (state: IReduxStore) => {
  const activePodcastInfo = selector_activePodcastInfo(state)

  const { yleId }: any = activePodcastInfo.podcastInfo || {}

  return {
    yleId
  }
}
const BeforeContinueToFullPodcastOverlay = connect(mapStateToProps)(props => {
  const {
    yleId,
    isToggleBeforeContinueToFullPodcastOverlay,
    onStartDiscoveringPodcast,
    onToggleBeforeContinueToFullPodcastOverlay
  } = props

  React.useEffect(
    () => {
      if (isToggleBeforeContinueToFullPodcastOverlay) {
        YTMLog({ userClickListenFullClipAtAreena: { yle_id: yleId } })
      }
    },
    [isToggleBeforeContinueToFullPodcastOverlay]
  )

  if (!isToggleBeforeContinueToFullPodcastOverlay) {
    return null
  }

  const onClose = () => {
    onToggleBeforeContinueToFullPodcastOverlay()
    onStartDiscoveringPodcast()
  }

  return (
    <div styleName="show-info--wrapper">
      <div styleName="show-info--inner">
        <div styleName="show-info--inner-content">
          <div styleName="show-info--content">
            <div styleName="show-info--content--before-continue">
              <div>
                <p>Kerro mielipiteesi! Haluaisitko auttaa meitä kehittämään palvelua vastaamalla lyhyen kyselyyn?</p>
                <div styleName="before-continue--item">
                  <span>Kyllä</span>
                  <Link01
                    href={`https://forms.gle/rU97iYGLipyx7gfD6`}
                    title={'Vastaa kyselyyn'}
                    label="Vastaa kyselyyn"
                    IconElement={IconOpenExternal}
                    iconWidth="80%"
                  />
                </div>
                <div styleName="before-continue--item">
                  <span>Ei kiitos</span>
                  <Link01
                    onClick={() => {
                      window.open(`https://areena.yle.fi/audio/${yleId}`, '_self')
                      onClose()
                    }}
                    // href={`https://areena.yle.fi/audio/${yleId}`}
                    title={'Kuuntele koko jakso Areenassa'}
                    label="Kuuntele koko jakso Areenassa"
                    IconElement={IconOpenExternal}
                    iconWidth="80%"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})

export { BeforeContinueToFullPodcastOverlay }
