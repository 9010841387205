import { createSelector } from 'reselect'

import { IReduxStore } from '@/App/redux/loadReducers'

const selector_discoverPodcastsLoading = createSelector(
  (state: IReduxStore) => {
    const { httpRequest } = state.yleDiscoverPodcastsStore

    return httpRequest.loading
  },
  (discoverPodcastsLoading): boolean => discoverPodcastsLoading
)

export { selector_discoverPodcastsLoading }
