// npm
import * as React from 'react'

interface IProps {
  discoverPodcastsLoading: boolean
  thumbnailSrc: string
}

// styles
import './ClipSplash.scss'

function getAverageRGB(imgEl) {
  const blockSize = 5 // only visit every 5 pixels
  const defaultRGB = { r: 0, g: 0, b: 0 } // for non-supporting envs
  const canvas = document.createElement('canvas')
  const context = canvas.getContext && canvas.getContext('2d')
  const rgb = { r: 0, g: 0, b: 0 }
  let data,
    i = -4,
    count = 0

  if (!context) {
    return defaultRGB
  }

  const height = (canvas.height = imgEl.naturalHeight || imgEl.offsetHeight || imgEl.height)
  const width = (canvas.width = imgEl.naturalWidth || imgEl.offsetWidth || imgEl.width)

  context.drawImage(imgEl, 0, 0)

  try {
    data = context.getImageData(0, 0, width, height)
  } catch (e) {
    /* security error, img on diff domain */
    return defaultRGB
  }

  const length = data.data.length

  while ((i += blockSize * 4) < length) {
    ++count
    rgb.r += data.data[i]
    rgb.g += data.data[i + 1]
    rgb.b += data.data[i + 2]
  }

  // ~~ used to floor values
  rgb.r = ~~(rgb.r / count)
  rgb.g = ~~(rgb.g / count)
  rgb.b = ~~(rgb.b / count)

  return rgb
}

const ClipSplashBackground = React.memo((props: IProps) => {
  const { discoverPodcastsLoading, thumbnailSrc } = props
  const [backgroundColor, setBackgroundColor]: any = React.useState(false)

  React.useEffect(() => {
    if (!thumbnailSrc) return

    const myImage = new Image()
    myImage.src = thumbnailSrc
    myImage.onload = () => {
      const { r, g, b } = getAverageRGB(myImage)
      setBackgroundColor([r, g, b].join(','))
    }
  }, [])

  if (discoverPodcastsLoading || !backgroundColor) {
    return <div styleName="clip-splash--background-inactive" />
  }

  return (
    <div styleName="clip-splash--background">
      <div
        styleName="clip-splash--background-inner"
        style={{ background: `linear-gradient(0deg, rgba(${backgroundColor},1) -5%, rgba(${backgroundColor},1) 100%)` }}
      >
        <img src={thumbnailSrc} />
      </div>
    </div>
  )
})

export { ClipSplashBackground }
