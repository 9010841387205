import * as React from 'react'

const IconPlay = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="38" viewBox="0 0 26 30">
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#FFF" transform="translate(0 0)">
          <path d="M13 2L27 27 -1 27z" transform="rotate(90 13 14.5)" />
        </g>
        <path d="M0 0L48 0 48 48 0 48z" />
      </g>
    </svg>
  )
}

export { IconPlay }
