import { STORE_NAME } from '.'

import { IavailablePodcastsState } from './typescript'

// action constants
import {
  GO_NEXT_ACTIVE_PODCAST,
  GO_PREVIOUS_ACTIVE_PODCAST,
  END_REACHED,
  FETCH_DISCOVER_PODCASTS,
  FETCH_DISCOVER_PODCASTS_SUCCESS,
  FETCH_DISCOVER_PODCASTS_ERROR,
  SET_ACTIVE_DISCOVER_PODCASTS
} from './actions'

export const defaultState: IavailablePodcastsState = {
  httpRequest: {
    loading: false,
    error: false,
    res: false
  },
  activePodcast: {
    yleId: false,
    index: -1
  }
}

const reducer_yleDiscoverPodcasts = {
  [STORE_NAME]: (state: IavailablePodcastsState, { type, payload }): IavailablePodcastsState => {
    switch (type) {
      case GO_NEXT_ACTIVE_PODCAST:
        return { ...state, activePodcast: payload }

      case GO_PREVIOUS_ACTIVE_PODCAST:
        return { ...state, activePodcast: payload }

      case END_REACHED:
        return { ...state, ...payload }

      case FETCH_DISCOVER_PODCASTS:
        return {
          ...state,
          httpRequest: {
            ...state.httpRequest,
            loading: true
          }
        }

      case FETCH_DISCOVER_PODCASTS_SUCCESS:
        return {
          ...state,
          httpRequest: {
            ...state.httpRequest,
            loading: false,
            error: false,
            res: payload.res
          }
        }

      case FETCH_DISCOVER_PODCASTS_ERROR:
        return {
          ...state,
          httpRequest: {
            ...state.httpRequest,
            loading: false,
            error: true
          }
        }

      case SET_ACTIVE_DISCOVER_PODCASTS:
        return {
          ...state,
          activePodcast: payload
        }

      default:
        return state ? state : { ...defaultState }
    }
  }
}

export { reducer_yleDiscoverPodcasts }
