// @ts-nocheck
import { IYTMSend, YleEventsEnum } from './yleTagEvents'

const yleTagManager = (function() {
  if (process.env.STAGE === 'dev-local') {
    console.warn('Note: YleTagManager disabled in stage dev-offline')
    return
  }

  function e(n, t) {
    return (
      '//' +
      (function(n) {
        return 'production' === n ? 'tag-manager.yle.fi' : 'test' === n ? 'tag-manager-test.yle.fi' : ''
      })(t) +
      '/' +
      (function(n, t) {
        return n + '-' + t + '.js'
      })(n, t)
    )
  }
  function r(n, t) {
    const e = document.createElement('script'),
      r = document.getElementsByTagName('script')[0]
    ;(e.async = 1),
      (e.src = n),
      r.parentNode.insertBefore(e, r),
      (e.onload = function() {
        'function' == typeof t && t()
      })
  }
  return {
    initializeAnalytics: function(n, t) {
      switch (t) {
        case 'prod':
        case 'production':
          r(e(n, 'production'))
          break
        case 'test':
          r(e(n, 'test'))
          break
        default:
          console.error('Unknown environment: ' + t)
      }
    },
    loadAnalyticsScript: r
  }
})()

window.yleTagManager = yleTagManager

const YTMLog = (data: IYTMSend) => {
  if (process.env.STAGE === 'dev-local') return

  if (!data || typeof data !== 'object') {
    console.warn('received invaild YTM log data', data)
    return
  }

  const eventName = Object.keys(data)[0]
  const interactionKey = YleEventsEnum[eventName]

  if (!interactionKey) {
    console.warn('received invaild YTM log data', data)
    return
  }

  const eventData = {
    yle_timestamp: new Date().getTime().toString(),
    yle_interaction: interactionKey,
    yyaRoutingKey: 'omapodcast',
    ...data[eventName]
  }

  if (!window.yleAnalytics || !window.yleAnalytics.trackEvents) {
    console.warn('event not logged, trackEvents not loaded.', eventData)
    return
  }

  window.yleAnalytics.trackEvents([eventData])
}

export { yleTagManager, YTMLog }
