import * as React from 'react'
import { connect } from 'react-redux'
import { Transition, TransitionGroup } from 'react-transition-group'
import { TweenMax } from 'gsap'

// actions
import { action_playerPlay } from '@/modules/audioPlayer/redux/actions'

// components
import { Link01 } from '@/Components/LinkButton01/Link01'

// styles
import './DiscoverPodcastsEndReachedInfo.scss'
import { IconOpenExternal } from '@/assets/ReactIcons/IconOpenExternal'
import { IconYle } from '@/assets/ReactIcons/IconYle'
import { Button01 } from '@/Components/LinkButton01/Button01'

const DoAnimation = React.memo(
  (props: any) => {
    React.useEffect(
      () => {
        if (props.inProp) {
          TweenMax.to(props.wrapperRef.current, 0.5, { y: '0%', ease: 'power4.out' })
        } else {
          TweenMax.to(props.wrapperRef.current, 0.5, { y: '100%', ease: 'power4.out' })
        }
      },
      [props.inProp]
    )
    return props.children
  },
  (a, b) => a.inProp === b.inProp
)

const Fade = (props: any) => {
  const { in: inProp, timeout, children, wrapperRef } = props

  return (
    <Transition in={inProp} timeout={timeout} unmountOnExit>
      <DoAnimation inProp={inProp} wrapperRef={wrapperRef}>
        {children}
      </DoAnimation>
    </Transition>
  )
}

const DiscoverPodcastsEndReachedInfo = connect()(props => {
  const { discoverPodcastsEndReached, onToggleDiscoverPodcastsEndReached, dispatch } = props

  const onClose = () => {
    onToggleDiscoverPodcastsEndReached()
    dispatch(action_playerPlay('discover-podcasts'))
  }

  const wrapperRef = React.useRef()

  let ContentElement

  if (!discoverPodcastsEndReached) {
    ContentElement = null
  } else {
    ContentElement = (
      <Fade key="end-reached" timeout={500} wrapperRef={wrapperRef}>
        <div styleName="show-info--wrapper">
          <div styleName="show-info--inner" ref={wrapperRef}>
            <div styleName="show-info--inner-content">
              <div styleName="show-info--content">
                <div styleName="show-info--content--discover-podcasts-end">
                  <div>
                    <p>Kaikki klipit kuunneltu!</p>
                    <p>
                      Kerro mielipiteesi! Haluaisitko auttaa meitä kehittämään palvelua vastaamalla lyhyen kyselyyn?
                    </p>
                    <div>
                      <div styleName="discover-podcasts-end--action-item">
                        <Link01
                          href={`https://forms.gle/rU97iYGLipyx7gfD6`}
                          title={'Vastaa kyselyyn'}
                          label="Vastaa kyselyyn"
                          IconElement={IconOpenExternal}
                          iconWidth="80%"
                        />
                      </div>
                    </div>
                    <div>
                      <div styleName="discover-podcasts-end--action-item">
                        <Button01 label="Kuuntele klipit uudestaan" onClick={onClose} IconElement={IconYle} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    )
  }

  return <TransitionGroup>{ContentElement}</TransitionGroup>
})

export { DiscoverPodcastsEndReachedInfo }
