// npm
import * as React from 'react'
import { connect } from 'react-redux'
import { format } from 'date-fns'
import { Transition, TransitionGroup } from 'react-transition-group'
import { TweenMax } from 'gsap'

// analytics
import { YTMLog } from '@/modules/yleTagManager/yleTagManager'

// actions
import { action_playerPlay, action_playerAutoplay } from '@/modules/audioPlayer/redux/actions'
import { action_goNextActivePodcast, action_endReached } from '@/modules/yleDiscoverPodcasts/redux/actions'

// selectors
import { selector_activePodcastInfo } from '@/modules/yleDiscoverPodcasts/selectors/selector_activePodcastInfo'
import { selector_audioPlayerState } from '@/modules/audioPlayer/selectors/selector_audioPlayerState'

// components
import { Link01 } from '@/Components/LinkButton01/Link01'

// typescript
import { IpodcastInfo } from '@/modules/yleDiscoverPodcasts/redux/typescript'

// icons
import { IconYle } from '@/assets/ReactIcons/IconYle'
import { IconOpenExternal } from '@/assets/ReactIcons/IconOpenExternal'

// styles
import './ClipInfoOverlay.scss'
import { selector_pickPodcastsToDiscover } from '@/modules/yleDiscoverPodcasts/selectors/selector_pickPodcastsToDiscover'

type IPropsTabContent1 = Pick<
  IpodcastInfo,
  'yleId' | 'episodeDescription' | 'episodeName' | 'publishedDate' | 'fullPodcastDuration' | 'episodeTitle'
>

const TabContent1 = (props: IPropsTabContent1) => {
  const { yleId, episodeTitle, episodeName, episodeDescription, fullPodcastDuration, publishedDate } = props

  // expected format: H:m (0:45, 1:10, etc)
  const formattedDate = format(new Date(publishedDate), `dd.MM.yyyy`)
  const formatHHMMSS = t => {
    const [hours, minutes] = t.split(':')

    if (hours.length === 1 && hours.match(/^0/)) {
      return `${minutes}min`
    }

    return `${hours}t ${minutes.replace(/^(0)/, '')}min`
    // return formatted
  }

  return (
    <div styleName="show-info--content">
      <p styleName="show-info--content--date">
        {formatHHMMSS(fullPodcastDuration)} - {formattedDate}
      </p>
      <div styleName="show-info--content--content">
        <div dangerouslySetInnerHTML={{ __html: episodeDescription }} />
        <div styleName="show-info--content--extra-areena--action-item">
          <Link01
            href={`https://areena.yle.fi/audio/${yleId}`}
            title={`${episodeTitle}: ${episodeName}`}
            label="Kuuntele koko jakso Areenassa"
            IconElement={IconYle}
            onClick={e => {
              e.preventDefault()
              YTMLog({ userClickListenFullClipAtAreena: { yle_id: yleId } })
              setTimeout(() => {
                window.location.href = `https://areena.yle.fi/audio/${yleId}`
              }, 300)
              return false
            }}
          />
        </div>
      </div>
      <div styleName="show-info--content--extra-areena">
        <div styleName="show-info--content--extra-areena-opinion">
          <p>Kerro mielipiteesi! Autathan meitä kehittämään palvelua vastaamalla lyhyeen kyselyyn.</p>
          <div styleName="show-info--content--extra-areena--action-item">
            <Link01
              href={`https://forms.gle/rU97iYGLipyx7gfD6`}
              title={'Vastaa kyselyyn'}
              label="Vastaa kyselyyn"
              IconElement={IconOpenExternal}
              iconWidth="80%"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const TabContent2 = (props: Pick<IpodcastInfo, 'podcastSeriesDescription'>) => {
  const { podcastSeriesDescription } = props

  const content = podcastSeriesDescription.replace(/(?:\r\n|\r|\n)/g, '<br>')

  return <div styleName="show-info--content" dangerouslySetInnerHTML={{ __html: content }} />
}

interface IProps {
  podcastInfo: IpodcastInfo
  openMorePodcastInfo: () => void
  onToggleOpenMorePodcastInfo: () => void
  dispatch: (payload: any) => void
}

const DoAnimation = React.memo(
  (props: any) => {
    React.useEffect(
      () => {
        if (props.inProp) {
          TweenMax.to(props.wrapperRef.current, 0.5, { y: '0%', ease: 'power4.out' })
        } else {
          TweenMax.to(props.wrapperRef.current, 0.5, { y: '100%', ease: 'power4.out' })
        }
      },
      [props.inProp]
    )
    return props.children
  },
  (a, b) => a.inProp === b.inProp
)

const Fade = (props: any) => {
  const { in: inProp, timeout, children, wrapperRef } = props

  return (
    <Transition in={inProp} timeout={timeout} unmountOnExit>
      <DoAnimation inProp={inProp} wrapperRef={wrapperRef}>
        {children}
      </DoAnimation>
    </Transition>
  )
}

const Content = (props: IProps) => {
  const { wrapperRef, podcastInfo, onToggleOpenMorePodcastInfo } = props

  // states
  const [selectedTabIndex, setTabIndex] = React.useState(0)

  // podcastInfo
  const {
    yleId,
    episodeTitle,
    episodeName,
    episodeDescription,
    publishedDate,
    fullPodcastDuration,
    podcastSeriesDescription
  } = podcastInfo

  return (
    <div styleName="show-info--wrapper">
      <div styleName="show-info--inner" ref={wrapperRef}>
        <div styleName="show-info--inner-content">
          <div styleName="show-info--header">
            <div styleName="show-info--header-inner">
              <div styleName="show-info--header-close">
                <button
                  styleName="show-info--header-close-button"
                  onClick={() => {
                    onToggleOpenMorePodcastInfo()
                  }}
                >
                  &times;
                </button>
              </div>
              <div styleName="show-info--header-label">
                <div>{episodeTitle}</div>
                <div>{episodeName}</div>
              </div>
            </div>
          </div>
          <div styleName="show-info--tabs">
            <ul>
              <li>
                <button
                  onClick={() => setTabIndex(0)}
                  styleName={selectedTabIndex === 0 ? 'show-info--tabs-button_active' : 'show-info--tabs-button'}
                >
                  Jakso
                </button>
              </li>
              <li>
                <button
                  onClick={() => setTabIndex(1)}
                  styleName={selectedTabIndex === 1 ? 'show-info--tabs-button_active' : 'show-info--tabs-button'}
                >
                  Sarja
                </button>
              </li>
            </ul>
          </div>
          {selectedTabIndex === 0 ? (
            <TabContent1
              yleId={yleId}
              episodeTitle={episodeTitle}
              episodeName={episodeName}
              episodeDescription={episodeDescription}
              publishedDate={publishedDate}
              fullPodcastDuration={fullPodcastDuration}
            />
          ) : (
            <TabContent2 podcastSeriesDescription={podcastSeriesDescription} />
          )}
        </div>
      </div>
    </div>
  )
}

const mapShowInfoStateToProps = state => {
  const activePodcastInfo = selector_activePodcastInfo(state)
  const audioPlayerState = selector_audioPlayerState(state)
  const podcastsToDiscover = selector_pickPodcastsToDiscover(state)
  const podcastInfo = activePodcastInfo.podcastInfo

  const totalPodcastIndexes = (podcastsToDiscover && podcastsToDiscover.length - 1) || -1
  const isLastPodcastItem = activePodcastInfo.index === totalPodcastIndexes

  return { podcastInfo, audioPlayerState, totalPodcastIndexes, isLastPodcastItem }
}
const ClipInfoOverlay = connect(mapShowInfoStateToProps)(
  React.memo((props: any) => {
    // todo: fix typescript props
    const {
      podcastInfo,
      isOpenMorePodcastInfo,
      onToggleOpenMorePodcastInfo,
      onToggleDiscoverPodcastsEndReached,
      audioPlayerState,
      isLastPodcastItem,
      dispatch
    } = props

    // refs
    const prevOpenMorePodcastInfoRef = React.useRef(isOpenMorePodcastInfo)

    const audioPlayerStateRef: React.MutableRefObject<string> = React.useRef(audioPlayerState)
    audioPlayerStateRef.current = audioPlayerState

    const isLastPodcastItemRef: React.MutableRefObject<string> = React.useRef(isLastPodcastItem)
    isLastPodcastItemRef.current = isLastPodcastItem

    const wrapperRef = React.useRef()
    const openMorePodcastInfoRef = React.useRef(isOpenMorePodcastInfo)

    React.useEffect(
      () => {
        if (prevOpenMorePodcastInfoRef.current === isOpenMorePodcastInfo) return

        const { yleId } = podcastInfo

        if (isOpenMorePodcastInfo) {
          YTMLog({ userClickOpenClipInfo: { yle_id: yleId } })
          dispatch(action_playerAutoplay(false))
        } else {
          YTMLog({ userClickCloseClipInfo: { yle_id: yleId } })
          dispatch(action_playerAutoplay(true))
          // tiny delay to make the transition to next podcast a bit more casual (less happening at once)

          if (audioPlayerStateRef.current === 'stop') {
            setTimeout(() => {
              if (isLastPodcastItemRef.current) {
                onToggleDiscoverPodcastsEndReached()
                setTimeout(() => {
                  // slight delay to reset playlist index. (due to animations, otherwise too much going on at the same time)
                  dispatch(action_endReached())
                }, 500)
              } else {
                dispatch(action_goNextActivePodcast())
                dispatch(action_playerPlay('discover-podcasts'))
              }
            }, 500)
          }
          // if (selectedTabRef.current === 1) setTabIndex(0)
        }
        prevOpenMorePodcastInfoRef.current = isOpenMorePodcastInfo
      },
      [isOpenMorePodcastInfo]
    )

    let ContentElement

    if (!isOpenMorePodcastInfo) {
      ContentElement = null
    } else {
      ContentElement = (
        <Fade key="clip-info-overlay" timeout={500} wrapperRef={wrapperRef}>
          <Content
            podcastInfo={podcastInfo}
            wrapperRef={wrapperRef}
            isOpenMorePodcastInfo={openMorePodcastInfoRef}
            onToggleOpenMorePodcastInfo={onToggleOpenMorePodcastInfo}
            onToggleDiscoverPodcastsEndReached={onToggleDiscoverPodcastsEndReached}
          />
        </Fade>
      )
    }

    return <TransitionGroup>{ContentElement}</TransitionGroup>
  })
)

export { ClipInfoOverlay }
