// npm
import * as React from 'react'

// styles
import './LinkButton01.scss'

interface IProps {
  label?: string
  IconElement?: any
  onClick: () => any
}

const Button01 = (props: IProps) => {
  const { label, IconElement, onClick } = props

  if (!IconElement && !label) return null

  const iconOnly = IconElement && !label

  return (
    <button
      styleName={iconOnly ? 'linkbutton-01-wrapper_icon-only' : 'linkbutton-01-wrapper'}
      onClick={() => onClick && onClick()}
    >
      {IconElement && (
        <span styleName={iconOnly ? 'linkbutton-01-icon__only' : 'linkbutton-01-icon'}>
          <IconElement />
        </span>
      )}

      {label && <span styleName="linkbutton-01-label">{label}</span>}
    </button>
  )
}

export { Button01 }
