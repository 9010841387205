import { createSelector } from 'reselect'

import { IReduxStore } from '@/App/redux/loadReducers'

import { IpodcastInfo } from '@/modules/yleDiscoverPodcasts/redux/typescript'

// selectors
import { selector_pickPodcastsToDiscover } from './selector_pickPodcastsToDiscover'

const selector_pickPodcastToDiscoverAudioSrc = createSelector(
  selector_pickPodcastsToDiscover,
  (state: IReduxStore) => {
    const { res } = state.yleDiscoverPodcastsStore.httpRequest
    // todo: currently for quickfix cache reason, can't memoize array
    return (res && res.body && res.body.length) || -1
  },
  (availablePodcasts: IpodcastInfo[], length) => {
    if (length < 1) return false

    return availablePodcasts.map((podcast: IpodcastInfo) => podcast.audioSrc)
  }
)

export { selector_pickPodcastToDiscoverAudioSrc }
