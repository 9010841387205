import { IaudioPlayerState } from './typescript'

// action constants
import { CREATE_NEW_PLAYER, SET_PLAYER_INDEX, PLAYER_PLAY, PLAYER_STOP, PLAYER_AUTOPLAY } from './actions'

// store name shown in redux store
export const STORE_NAME = 'audioPlayerStore'

export const defaultState: IaudioPlayerState = {
  activePlaylistId: false,
  playerState: 'stop',
  autoplay: true,
  players: {}
}

const reducer_audioPlayer = {
  [STORE_NAME]: (state: IaudioPlayerState, { type, payload }): IaudioPlayerState => {
    switch (type) {
      case CREATE_NEW_PLAYER:
        return {
          ...state,
          ...payload
        }

      case SET_PLAYER_INDEX:
        state.players[payload.playerId].playerActiveIndex = payload.newIndex
        return {
          ...state
        }

      case PLAYER_PLAY:
        return {
          ...state,
          activePlaylistId: payload.playlistId,
          playerState: 'play'
        }

      case PLAYER_STOP:
        return {
          ...state,
          activePlaylistId: payload.playlistId,
          playerState: 'stop'
        }

      case PLAYER_AUTOPLAY:
        return {
          ...state,
          autoplay: payload
        }

      default:
        return state
          ? state
          : {
              ...defaultState
            }
    }
  }
}

export { reducer_audioPlayer }
