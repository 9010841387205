import { createSelector } from 'reselect'

import { IReduxStore } from '@/App/redux/loadReducers'

import { IpodcastInfo } from '../redux/typescript'
import { selector_pickPodcastsToDiscover } from './selector_pickPodcastsToDiscover'

const selector_activePodcastInfo = createSelector(
  // get available podcasts list
  selector_pickPodcastsToDiscover,
  // get active podcast info
  (state: IReduxStore) => {
    if (!state || !state.yleDiscoverPodcastsStore) {
      return false
    }

    const { activePodcast } = state.yleDiscoverPodcastsStore
    return activePodcast
  },
  // find matching podcast from podcasts list and use it in podcastInfo
  (podcastsToDiscover, activePodcast) => {
    let podcastInfo: IpodcastInfo | false
    // get podcast info
    if (activePodcast && activePodcast.yleId && podcastsToDiscover && podcastsToDiscover.length > 0) {
      podcastInfo = podcastsToDiscover.find(({ yleId }) => yleId === activePodcast.yleId)
    } else {
      podcastInfo = false
    }

    const activePodcastInfo = {
      ...(activePodcast
        ? // active podcast IS set
          activePodcast
        : // active podcast IS NOT set
          {
            yleId: false,
            index: -1
          }),
      podcastInfo
    }

    return activePodcastInfo
  }
)

export { selector_activePodcastInfo }
