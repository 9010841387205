// npm
import * as React from 'react'

interface IProps {
  discoverPodcastsLoading: boolean
  thumbnailSrc: string
}

// styles
import './ClipSplash.scss'

const ClipSplashImage = React.memo((props: IProps) => {
  const { discoverPodcastsLoading, thumbnailSrc } = props

  const getImageContent = () => {
    if (discoverPodcastsLoading || !thumbnailSrc) {
      return null
    }

    return <img src={thumbnailSrc} />
  }

  return <div styleName="clip-splash--content--item--image-wrapper">{getImageContent()}</div>
})

export { ClipSplashImage }
