import * as React from 'react'
import { render as reactRender } from 'react-dom'
import { App } from './App/App'

import { gsap } from 'gsap'
import { CSSPlugin } from 'gsap/CSSPlugin'

gsap.registerPlugin(CSSPlugin)

const AppRootElement = document.getElementById('app_root')

if (process.env.isHotReload) {
  const AppHotReloaded = require('react-hot-loader/root').hot(App)
  reactRender(<AppHotReloaded />, AppRootElement)
} else {
  reactRender(<App />, AppRootElement)
}
