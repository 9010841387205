// prettier-ignore
export enum YleEventsEnum {
  "userManualNextClip" = "omapodcast.user_manual_next_clip",
  "userManualPreviousClip" = "omapodcast.user_manual_previous_clip",
  "userClickListenFullClipAtAreena" = "omapodcast.user_click_listen_full_clip_at_areena",
  "userClickOpenClipInfo" = "omapodcast.user_click_open_click_info",
  "userClickCloseClipInfo" = "omapodcast.user_click_close_clip_info",
  "clipLoading" = "omapodcast.clip_loading",
  "clipPlaying" = "omapodcast.clip_playing"
}

// User manually going to next clip
interface userManualNextClip {
  userManualNextClip: {
    yle_timestamp?: string
    yle_interaction?: YleEventsEnum.userManualNextClip
    yle_id: string
  }
}
// User manually going to previous clip
interface userManualPreviousClip {
  userManualPreviousClip: {
    yle_timestamp?: string
    yle_interaction?: YleEventsEnum.userManualPreviousClip
    yle_id: string // yle id of the previous clip id
  }
}
// User clicked to listen full clip at areena
interface userClickListenFullClipAtAreena {
  userClickListenFullClipAtAreena: {
    yle_timestamp?: string
    yle_interaction?: YleEventsEnum.userClickListenFullClipAtAreena
    yle_id: string // yle id of the next clip id
  }
}
// User clicked clip info button
interface userClickOpenClipInfo {
  userClickOpenClipInfo: {
    yle_timestamp?: string
    yle_interaction?: YleEventsEnum.userClickOpenClipInfo
    yle_id: string
  }
}
// User closes clip info
interface userClickCloseClipInfo {
  userClickCloseClipInfo: {
    yle_timestamp?: string
    yle_interaction?: YleEventsEnum.userClickCloseClipInfo
    yle_id: string
  }
}
// new/unique clip is being loaded/buffered (not counting stop/resume)
interface clipLoading {
  clipLoading: {
    yle_timestamp?: string
    yle_interaction?: YleEventsEnum.clipLoading
    yle_id: string
  }
}
// new/unique clip that has started playing audio (not counting stop/resume)
interface clipPlaying {
  clipPlaying: {
    yle_timestamp?: string
    yle_interaction?: YleEventsEnum.clipPlaying
    yle_id: string
  }
}

export type IYTMSend =
  | userManualNextClip
  | userManualPreviousClip
  | userClickListenFullClipAtAreena
  | userClickCloseClipInfo
  | clipLoading
  | clipPlaying
  | userClickOpenClipInfo
