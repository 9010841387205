import { createSelector } from 'reselect'

import { IReduxStore } from '@/App/redux/loadReducers'

const selector_discoverPodcastsError = createSelector(
  (state: IReduxStore) => {
    const { httpRequest } = state.yleDiscoverPodcastsStore

    return httpRequest.error
  },
  (discoverPodcastsError): boolean => discoverPodcastsError
)

export { selector_discoverPodcastsError }
