import * as React from 'react'
import { Helmet } from 'react-helmet'

import { AppReduxProvider } from './redux/ReduxProvider'

// layout
import { LayoutDefault } from '@/layouts/LayoutDefault/LayoutDefault'

// pages
import { PageDiscoverPodcasts } from '@/pages/PageDiscoverPodcasts/PageDiscoverPodcasts'

// global/shared scss styles
import '../assets/globalScssStyles/reset.scss'
import '../assets/globalScssStyles/global.scss'

// analytics
if (process.env.STAGE !== 'dev-local') {
  const { yleTagManager } = require('@/modules/yleTagManager/yleTagManager')

  const analyticsEnv = process.env.STAGE === 'prod' ? 'prod' : 'test'

  yleTagManager.initializeAnalytics('omapodcast', analyticsEnv)
}

// console log app version
console.log(`App version: ${process.env.npm_package_version}`)

const App = () => {
  return (
    <LayoutDefault>
      <PageDiscoverPodcasts />
    </LayoutDefault>
  )
}

const AppEnhancers = () => {
  return (
    <AppReduxProvider>
      <Helmet defaultTitle="Löydä oma podcastisi">
        <html lang="en" />
      </Helmet>
      <App />
    </AppReduxProvider>
  )
}

export { AppEnhancers as App }
