import * as React from 'react'

const IconInfo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <path
          fillRule="nonzero"
          stroke="#FFF"
          strokeWidth="3"
          d="M24 5.5c-5.106 0-9.73 2.072-13.08 5.42A18.442 18.442 0 005.5 24c0 5.106 2.072 9.73 5.42 13.08A18.442 18.442 0 0024 42.5c5.106 0 9.73-2.072 13.08-5.42A18.442 18.442 0 0042.5 24c0-5.106-2.072-9.73-5.42-13.08A18.442 18.442 0 0024 5.5z"
        />
        <path fill="#FFF" fillRule="nonzero" d="M26 34L22 34 22 22 26 22z" />
        <path fill="#FFF" fillRule="nonzero" d="M26 18L22 18 22 14 26 14z" />
        <path d="M0 0L48 0 48 48 0 48z" />
      </g>
    </svg>
  )
}

export { IconInfo }
