import { createSelector } from 'reselect'

import { IReduxStore } from '@/App/redux/loadReducers'

const selector_discoverPodcastsLoaded = createSelector(
  (state: IReduxStore) => {
    const { httpRequest } = state.yleDiscoverPodcastsStore

    return !!httpRequest.res
  },
  (discoverPodcastsLoaded): boolean => discoverPodcastsLoaded
)

export { selector_discoverPodcastsLoaded }
